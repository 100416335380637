<template>
	<div class="item">
		<label>
			<span>{{item.label}}</span>
			<span v-if="item.required"> *</span>
		</label>
		<div class="select" v-click-outside="handleOutside">
			<div class="sel" v-if="!down" @click="down = true">
				<span v-if="!Object.keys(selectedItem).length">Select a {{item.label}}</span>
				<div class="selItem" v-else>
					<figure v-if="selectedItem.image">
						<img :src="selectedItem.image" alt="">
					</figure>
					<span>{{selectedItem.name}}</span>
				</div>
			</div>
			<input v-else class="chosen-value" type="text" v-model="searchKey" placeholder="Type to filter">
			<transition name="slide-down">
				<ul class="value-list" v-if="down">
					<li v-if="Object.keys(selectedItem).length && !searchKey.length" @click="handleRemove">
						<span class="rem">Remove Selected</span>
					</li>
					<template v-for="(opt, n) in filteredOptions" :key="n">
						<li @click="handleSelect(opt)">
							<figure v-if="opt.image">
								<img :src="opt.image" alt="">
							</figure>
							<span>{{opt.name}}</span>
						</li>
					</template>
				</ul>
			</transition>
		</div>
	</div>
</template>

<script>

	export default {
		props: {
			item: {
				type: Object
			}
		},

		data() {
			return {
				down: false,
				options: [],
				filteredOptions: [],
				searchKey: '',
				selectedItem: this.item.value || {}
			}
		},

		watch: {
			options(val) {
				this.filteredOptions = val
			},
			searchKey(val) {
				if(val.length) this.filteredOptions = this.options.filter(el => el.name.toLowerCase().includes(val.toLowerCase()))
				else this.filteredOptions = this.options
			},
			selectedItem: {
				deep: true,
				handler(val) {
					this.$emit('data', [this.item.name, val])
				}
			},
			item: {
				deep: true,
				handler() {
                    this.optionsResponse()
					this.selectedItem = {}
                }
            }
		},

		methods: {
			optionsResponse() {
				const isPromise = (promise) => {
					return !!promise && typeof promise.then === "function";
				};

				if (isPromise(this.item.options)) {
					Promise.resolve(this.item.options).then((value) => {
						this.options = value;
					});
				} else this.options = this.item.options;
			},

			handleOutside() {
				this.down = false
			},

			handleSelect(item) {
				this.selectedItem = item
				this.down = false

				setTimeout(() => {
					this.searchKey = ''
				}, 200);
			},

			handleRemove() {
				this.down = false
				this.selectedItem = {}
			}
		},

		mounted() {
			this.optionsResponse()
		}
	}
</script>

<style lang="scss" scoped>
	.slide-down-enter-active {
	animation: slide-down 0.5s;
	}
	.slide-down-leave-active {
	animation: slide-down 0.5s reverse;
	}
	label {
		font-weight: 500;
        font-size: 1rem;
		color: var(--primary-color);
	}

	.select {
		width: 100%;
		display: flex;
		flex-direction: column;
		.sel, input {
			font-weight: 500;
			border: solid 1px #cad6e2;
			border-radius: 2px;
			padding: 10px 15px;
			user-select: none;
		}

		.sel {
			cursor: pointer;

			.selItem {
				display: flex;
				align-items: center;
				gap: 10px;
				figure {
					width: 20px;
					height: 20px;
					border-radius: 50%;
					overflow: hidden;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}

		ul {
			height: 100%;
			max-height: 200px;
			overflow: auto;
			border: solid 1px #cad6e2;
			border-top: none;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 0;
			&::-webkit-scrollbar {
				width: 10px;
			}
			&::-webkit-scrollbar-track {
				background: transparent;
			}
			&::-webkit-scrollbar-thumb {
				background: #f2f5f8;
				border: 2px solid rgba(0, 0, 0, 0);
				background-clip: padding-box;
				border-radius: 9999px;
				transition: 0.2s;
			}
			&::-webkit-scrollbar-thumb:hover {
				background: #e6e8eb;
				border: 2px solid rgba(0, 0, 0, 0);
				background-clip: padding-box;
				border-radius: 9999px;
			}
			li {
				width: 100%;
				padding: 10px;
				user-select: none;
				cursor: pointer;
				border-bottom: 1px solid #eeeeee;
				display: flex;
				align-items: center;
				gap: 10px;
				figure {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					overflow: hidden;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						height: 100%;
						object-fit: cover;
					}
				}


				.rem {
					color: $red;
				}
			}
		}
	}

@keyframes slide-down {
  0% {
    opacity: 0;
    height: 0px;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    overflow: hidden;
  }
}

</style>