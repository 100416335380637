import analyticsInstance from "@/services/AnalyticsInstance";

export default{
    async storeAnalytics(state, payload){
        const response = await analyticsInstance.post('storeData', payload)
        console.log(response)
    },
    async getAnalytics(){
        console.log('oi')
        const response = await analyticsInstance.post('getData')
        console.log(response)
    },
}