export default{
    setDisabled(state, payload){
        state.notChat = payload
    },
    setCallStatus(state, payload){
        state.callStarted = payload
    },
    setCallId(state, payload){
        state.callId = payload
    },
}