<template>
    <main class="desk" v-if="!isMobile()">
        <Organization />
        <Modules />
        <Settings v-if="getUserProfile.role?.id === 4" />
    </main>
    <template v-else>
        <Mobile />
    </template>
</template>

<script>
import Modules from './Desk/Modules.vue'
import Organization from './Desk/Organization.vue'
import Settings from './Desk/Settings.vue'
import { mapGetters } from 'vuex'
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import isMobile from '@/mixins/isMobile'
import Mobile from './Mobile/Index.vue'
export default {
    components: {
        Modules,
        Organization,
        Settings,
        Mobile
    },

    mixins: [isMobile],

    computed: {
        ...mapGetters("account", {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),
    }
}
</script>

<style lang="scss" scoped>
    .desk {
        width: 8rem;
        position: sticky;
        height: 100vh;
        top: 0;
        left: 0;
        background: $bar;
        display: flex;
        flex-direction: column;
        z-index: 2;

        @media only screen and (max-width: 768px) {
            width: 4rem;
            justify-content: space-between;
            padding: 20px 0;
        }
    }
</style>