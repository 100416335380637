import axios from "axios";

const axiosInstance = axios.create({});
axiosInstance.interceptors.request.use((config) => {
    config.headers = {
        'Content-Type': 'application/json',
        'client_id': 'MjAyMjA0MTlfUmVtb3R'
    }

    let url = 'https://analytics.immera.io/';
    config.baseURL = url;

    return config;
});

export default axiosInstance;
