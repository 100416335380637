import { createApp } from 'vue'
import App from './App.vue'
import i18n from './locales';
import router from './router'
import store from './store'
import TitleMixin from './mixins/TitleMixin'
import mitt from 'mitt';
import moment from 'moment';
import vClickOutside from "click-outside-vue3";

// Layouts
import AppLayout from './layouts/app/AppLayout.vue';
import AuthLayout from './layouts/auth/AuthLayout.vue';
import RegisterLayout from './layouts/auth/RegisterLayout.vue';
import HomeLayout from './layouts/home/HomeLayout.vue';

// Additional Sidebars
import Sidebar from './layouts/app/additional_sidebar/Sidebar.vue';

const emitter = mitt();

const app = createApp(App);

// moment js
moment.locale('en');
app.config.globalProperties.$moment = moment;

//FCM

app.mixin(TitleMixin);
app.component('app-layout', AppLayout);
app.component('auth-layout', AuthLayout);
app.component('register-layout', RegisterLayout);
app.component('home-layout', HomeLayout);
app.component('main-sidebar', Sidebar);

app.use(i18n);
app.use(store);
app.use(router);
app.use(vClickOutside);

app.config.globalProperties.emitter = emitter;
app.mount('#app');
