<template>
    <form @submit.prevent="sendData" id="user-form" class="user-form">
        <div class="single">
            <div class="profile-pic">
                <div class="import-image" ref="impBtn">
                        <label>
                            <input type="file" accept="image/png, image/jpeg" @change="importImage">

                            <template v-if="!clientLogo">
                                <img src="/assets/images/photo-icon.png" alt="">
                            </template>
                            <template v-else>
                                <img :src="clientLogo" class="importedImg">
                            </template>
                        </label>
				</div>
            </div>
        </div>
        <div class="single input">
            <div class="item">
                <label for="first">Name</label>
                <input type="text" v-model="formData['name']" placeholder="Your first name" required>
            </div>
        </div>
        <div class="row">
            <div class="item">
                <label for="countries">Country</label>
                <select name="countries" v-model="formData['country']" id="" required>
                    <option value="" selected disabled>Select your Country</option>
                    <option v-for="(country, index) in countries" :key="index" :value="country.id">{{country.name}}</option>
                </select>
            </div>
        </div>
        <div class="double">
            <div class="item">
                <label for="pn">Adress</label>
                <input type="text" v-model="formData['address']" placeholder="Your address">
            </div>
            <div class="item">
                <label for="email">Post Code</label>
                <input type="number" v-model="formData['postCode']" placeholder="0123">
            </div>
        </div>
        <div class="double">
            <div class="item">
                <label for="first">Responsable Name</label>
                <input type="text" v-model="formData['resp_name']" placeholder="Responsable person name">
            </div>
            <div class="item">
                <label for="last">Responsable Phone Number</label>
                <input type="number" v-model="formData['resp_number']" placeholder="(+351) 919999999">
            </div>
        </div>
    </form>
    <div class="submit">
        <button  type="submit" form="user-form">confirm registration</button>
    </div>

    <div class="error" v-if="error" @click="error=false">
        <span>Something went wrong.<br />We're sorry for the inconvenience.</span>
    </div>

    <div v-if="loader" class="loading">
        <span class="loader"></span>
    </div>

</template>

<script>
import axiosInstance from '@/services/AxiosTokenInstance';

export default {
    props: {
        client_id: {
            required: true,
            type: Number,
        },
        client_data: {
            required: true,
            type: Array,
        }
    },

    data() {
        return {
            formData: {name: this.client_data.name, country: '', city: ''},
            error: false,
            clientLogo: '',
            countries: [],
            loader: false,
            responseStatus: 0,
        }
    },

    methods: {
        async sendData() {
                this.loader = true
                let toSend = new FormData();

                toSend.append('logo', this.formData.image.originalFile)
                toSend.append('client_id', this.client_id)
                toSend.append('client_name', this.formData.name)
                toSend.append('client_address', this.formData.address)
                toSend.append('client_post_code', this.formData.postCode)
                toSend.append('client_city', /*this.formData.city*/ 'empty')
                toSend.append('client_country_id', this.formData.country)
                toSend.append('client_contact_person_phone', this.formData.resp_number)
                toSend.append('client_contact_person_name', this.formData.resp_name)

                await axiosInstance.post('client-registration-completion', toSend,{
					headers : {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
                    this.responseStatus = response.status
                }).finally(() => {
                    this.loader = false
                    if (this.responseStatus === 200) {
                        this.$emit('created')
                    }
                    else this.error = true
                })

        },

        importImage() {
            const file = event.target.files[0]

				if (FileReader && file) {
					const fr = new FileReader();
					fr.readAsDataURL(file);
					fr.onload = () => {
						this.formData.image = {name: file.name, link: fr.result, originalFile: file}
                        this.clientLogo = fr.result
					}
				}
        }
    },

    async mounted() {
        const fetchCountries = await axiosInstance.get(`countries-list`);
        this.countries = fetchCountries.data.data
    }
}
</script>

<style lang="scss" scoped>
    .user-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 25px;

        .row {
            .item {
                display: flex;
                flex-direction: column;

                input, select {
                    border: 1px solid #CAD6E2;
                    border-radius: 2px;
                    padding: 10px;

                    &:focus {
                        outline: none;
                    }
                }
            }   
            
        }
    }

    .user-form .single{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .user-form .single.input .item{
        width: 100%;
        display: flex;
        flex-direction: column;
    }

	.user-form .single .profile-pic .import-image {
		background: #F2F5F8;
		color: var(--primary-color);
		font-size: 3rem;
		transition: 0.2s;
		width: 100px;
        height: 100px;
		overflow: hidden;
		border: 1px solid #0515241F;
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.user-form .single .profile-pic .import-image .importedImg{
		width: 100%;
        height: 100%;
        object-fit: cover;
	}

	.user-form .single .profile-pic .import-image label  {
		margin: 0;
		cursor: pointer;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.user-form .single .profile-pic .import-image label input {
		display: none;
	}

	.user-form .single .profile-pic .import-image:hover {
		background: var(--primary-color);
	}

    .user-form .double {
        display: flex;
        gap: 10px;
    }
    .user-form .double .item{
        width: calc(50% - 5px);
        display: flex;
        flex-direction: column;
    }

    .user-form .double .item input.email {
        background: #CAD6E2;
        color: white;
    }

    .user-form .single .item input,
    .user-form .double .item input,
    .user-form .double .item select{
        border: 1px solid #CAD6E2;
        border-radius: 2px;
        padding: 10px;
    }
    .user-form .silge .item input::placeholder,
    .user-form .double .item input::placeholder {
        color: #CAD6E2;
    }

    .user-form .single .item input:focus,
    .user-form .double .item input:focus,
    .user-form .double .item select:focus {
        outline: none;
    }

    .submit{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .submit button{
        background: var(--primary-color);
        border-radius: 30px;
        padding: 10px 30px;
        color: white;
        text-transform: capitalize;
        user-select: none;
        cursor: pointer;
        transition: 0.2s;
    }
    .submit button:hover{
        background: var(--primary-hover);
    }

    .error {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,0.4);
    }
    .error span{
        width: 200px;
        height: 200px;
        background: white;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: grey;
    }

    .loading {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0,0.4);
    }

    .loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid var(--primary-color);
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    }

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
</style>