<template>
    <div class="freeze-popup" @mouseup="isDown = []">
        <div class="freeze-container">
            <div class="title">
                <span>Draw on Image</span>
                <span class="close" @click="$emit('close')">x</span>
            </div>
            <div class="content">
                <div ref="wrap" class="wrap">
                    <img ref="cvSize" :src="image" alt="">
                    <canvas ref="canvas" class="canvas" @mousedown="handleMouseDown" @mousemove="handleMouseMove"></canvas>
                    <div class="menu" v-if="show">
                        <div class="item" :class="{active: freehand }" @click="freehand = !freehand">
                            <img src="/assets/images/ar/freehand.png" alt="freehand" title="Free Hand">
                        </div>
                        <div class="item" :class="{active: square }" @click="square = !square">
                            <img src="/assets/images/ar/square.png" alt="square" title="Square">
                        </div>
                        <div class="item" :class="{active: circle }" @click="circle = !circle">
                            <img src="/assets/images/ar/dry-clean.png" alt="circle" title="Circle">
                        </div>
                    </div>
                    <colorpicker :initColor="color" @newColor="handleNewColor" />
                </div>
                <div class="message">
                    <textarea v-model="message" ref="comment" placeholder="Add a comment (optional)"/>
                    <img @click="sendFiles" src="/assets/images/send_blue.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
import Colorpicker from './Colorpicker.vue'
export default {
    props: {
        image: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            colorpicker: false,
            color: '#fff',
            freehand: false,
            square: false,
            circle: false,
            isDown: [],
            show: true,
            message: ''
        }
    },

    watch: {
        freehand(val) {
            if(val) {
                this.setCanvasSize()
                this.square = false
                this.circle = false
            }
        },
        square(val) {
            if(val) {
                this.setCanvasSize()
                this.freehand = false
                this.circle = false
            }
        },
        circle(val) {
            if(val) {
                this.setCanvasSize()
                this.square = false
                this.freehand = false
            }
        },
    },

    components: {
        Colorpicker
    },

    methods: {
        handleNewColor(data) {
            this.color = data
        },

        setCanvasSize() {
            setTimeout(() => {
                const img = this.$refs.cvSize
                const canvas = this.$refs.canvas
                canvas.width = img.offsetWidth
                canvas.height = img.offsetHeight
            }, 200);
        },

        handleMouseDown(e) {
            if (this.freehand || this.circle || this.square) {
                const canvas = this.$refs.canvas
                this.isDown = {
                    prevX: 0,
                    prevY: 0,
                    currX: (e.offsetX - canvas.offsetLeft) + 10,
                    currY: (e.offsetY - canvas.offsetTop) + 10
                }
            }

        },

        handleMouseMove(event) {
            if(Object.keys(this.isDown).length) {
                if(this.freehand) this.draw(event)
                if(this.square) this.rect(event)
                if(this.circle) this.arc(event)
            }

        },

        draw(e) {
            const canvas = this.$refs.canvas
            const ctx = canvas.getContext("2d");
            this.isDown.prevX = this.isDown.currX;
            this.isDown.prevY = this.isDown.currY;
            this.isDown.currX = (e.offsetX - canvas.offsetLeft) + 10;
            this.isDown.currY = (e.offsetY - canvas.offsetTop) + 10;

            ctx.beginPath();
            ctx.lineCap = "round";
            ctx.strokeStyle = this.color;
            ctx.lineWidth = 5;
            ctx.moveTo(this.isDown.prevX, this.isDown.prevY);
            ctx.lineTo(this.isDown.currX, this.isDown.currY);
            ctx.stroke();
            ctx.closePath();
        },

        rect(e) {
            const canvas = this.$refs.canvas
            const ctx = canvas.getContext("2d");
            const x = (e.offsetX - canvas.offsetLeft) + 10
            const y = (e.offsetY - canvas.offsetTop) + 10;
            const width = x - this.isDown.currX;
            const height = y -this.isDown.currY;

            ctx.clearRect(0,0,canvas.width,canvas.height);
            ctx.beginPath();
            ctx.strokeStyle = this.color;
            ctx.lineWidth = 5;
            ctx.rect(this.isDown.currX, this.isDown.currY, width, height);
            ctx.stroke();
            ctx.closePath();
        },

        arc(e) {
            const canvas = this.$refs.canvas
            const ctx = canvas.getContext("2d");
            const x = (e.offsetX - canvas.offsetLeft) + 10
            const y = (e.offsetY - canvas.offsetTop) + 10;

            ctx.clearRect(0,0,canvas.width,canvas.height);
            ctx.beginPath();
            ctx.moveTo(this.isDown.currX, this.isDown.currY + (y-this.isDown.currY)/2);
            ctx.bezierCurveTo(this.isDown.currX, this.isDown.currY, x, this.isDown.currY, x, this.isDown.currY + (y-this.isDown.currY)/2);
            ctx.bezierCurveTo(x, y, this.isDown.currX, y, this.isDown.currX, this.isDown.currY + (y-this.isDown.currY)/2);
            ctx.strokeStyle = this.color;
            ctx.lineWidth = 5;
            ctx.closePath();
            ctx.stroke();
        },

        sendFiles() {
            this.show = false
            setTimeout(() => {
                html2canvas(this.$refs.wrap).then((canvas) => {
                    const dataURL = canvas.toDataURL()
                    var blobBin = atob(dataURL.split(',')[1]);
                    var array = [];
                    for(var i = 0; i < blobBin.length; i++) {
                        array.push(blobBin.charCodeAt(i));
                    }
                    const myBlob = new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
                    myBlob.name = 'generated image'
                    const file = new File([myBlob], "image.jpeg", {
                        type: myBlob.type,
                    });
                    this.$emit('message', {image: {original: file}, text: this.message})
                })
            }, 100);

        }
    },

    mounted() {
        this.setCanvasSize()
        window.addEventListener('keyup', (e) => {
            if(e.keyCode === 27) this.$emit('close')
        })
    }
}
</script>

<style lang="scss" scoped>
.freeze-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    background: rgba(0,0,0,0.3);
    backdrop-filter: blur(5px);

    .freeze-container {
        height: 80vh;
        background: white;
        .title {
            height: 10%;
            box-shadow: 0px 1px 6px #CAD6E280;
            border-bottom: solid 1px #F2F5F8;
            padding: 20px;
            text-transform: uppercase;
            color: black;
            font-weight: bold;
            font-size: 1.2rem;
            display: flex;
            justify-content: space-between;

            .close {
                cursor: pointer;
                user-select: none;
            }
        }
        .content {
            height: 90%;
            padding: 10px;
            overflow: auto;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            .wrap {
                display: flex;
                justify-content: center;
                .canvas {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                }
                .menu {
                    position: absolute;
                    top: 20px;
                    background: rgba(0,0,0,0.3);
                    display: flex;
                    justify-content: center;
                    border-radius: 15px;
                    overflow: hidden;
                    width: 150px;
                    transition: 0.2s;
                    &.color {
                        width: 300px;
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 0px;
                    }
                    .item {
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: 0.2s;
                        cursor: pointer;
                        &:hover {
                            background: rgba(0,0,0,0.5);
                        }
                        &.active {
                            background: rgba(0,0,0,0.5);
                        }
                        img {
                            width: 60%;
                            height: 60%;
                            filter: invert(1);
                        }
                    }
                }
            }

            .message {
                width: 100%;
                background: white;
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;
                border: solid 1px rgba(0,0,0,0.3);
                textarea {
                    width: 100%;
                    height: 70px !important;
                    resize: none;
                    padding: 10px 70px 10px 10px;
                    &:focus {
                        outline: none;
                    }
                }

                img {
                    position: absolute;
                    right: 20px;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>