<template>
    <div class="notifications" v-click-outside="handleOutside">
        <section v-if="filteredNotifications.length" @scroll="handleScroll">
            <template v-for="(notif, index) in filteredNotifications" :key="index">
                <article :class="{unread: !notif.read}" @click="handleNotif(index)">
                    <span>{{notif.title}}</span>
                </article>
            </template>
        </section>
        <div class="no-notifs" v-else>
            <span>Your notification box is clear.</span>
        </div>
    </div>
</template>

<script>
import nodeInstance from '@/services/NodeServiceinstance'
export default {
    props: {
        notifications: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            filteredNotifications: this.notifications
        }
    },

    methods: {
        async handleNotif(index) {
            this.filteredNotifications[index].read = true;
            this.$router.push({path:`${this.filteredNotifications[index].to}`})

            await nodeInstance.put(`/notifications/read/${this.filteredNotifications[index].id}`).finally(() => this.$emit('getUnreads'))
        },
        handleOutside() {
            this.$emit('close')
        },
        handleScroll(event) {
            const element = event.target
            const isBottom = Math.round(element.scrollTop * 1.6) >= element.scrollHeight
            if(isBottom) this.$emit('loadMore')
        }
    }
}
</script>

<style lang="scss" scoped>
.notifications {
    position: absolute;
    right: 0;
    height: 5rem;
    width: 15rem;
    z-index: 5;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 1px 6px #CAD6E280;
    overflow: hidden;

    section {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #f2f5f8;
            border: 2px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 9999px;
            transition: 0.2s;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #e6e8eb;
            border: 2px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 9999px;
        }
        article {
            padding: 10px 20px;
            border-bottom: solid 1px #CAD6E280;
            cursor: pointer;
            transition: 0.2s;
            &:hover {
                background: #CAD6E280;
            }

            &.unread {
                background: #0072FFce;
                color: white;
                border-bottom: solid 1px white;
                transition: 0.2s;
                &:hover {
                    background: var(--primary-color);
                }
            }

            span {
                font-size: 0.7rem;
                user-select: none;
            }
        }
    }

    .no-notifs {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.7rem;
        text-align: center;
        color: #CAD6E2;
        padding: 20px;
        user-select: none;
    }
}
</style>