import axios from "axios";
import store from '@/store'
import { GET_USER_TOKEN_GETTER } from "@/store/storeconstants";

const nodeServiceInstance = axios.create({});
nodeServiceInstance.interceptors.request.use((config) => {
    let token = store.getters[`auth/${GET_USER_TOKEN_GETTER}`];
    //token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiOTVjYzUwNDZjN2UwMTQ4OTM5YzBhMDAxZWFjNjIzMDliYjg4MGJiMjRlYWQ4M2IxYTJiNGE0ZDE5YTMzYjI0MDE0ODY0ODI1ZGUwNDVhYTUiLCJpYXQiOjE2MTg4MzA1ODEuNjQwMzAzLCJuYmYiOjE2MTg4MzA1ODEuNjQwMzA5LCJleHAiOjE2NTAzNjY1ODEuNjM1NjQ4LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.efH9hsaPeRFl-0rfNF3qvJq70itq6St6ku1469M0_8Jv_a1RngQe2a6LnCO1_8nA6Q-70Hm4VaG0LCGkZ8peKDkrjRJmEcrozR0qP8nJy_ZrfRq8kJEM__U8uDRSpKJYQ8f1Xj5r8I5-iwRm90wVqKjLRlrKbLRYu5Sg69SSuoc8L8iEMdZue5yzSRfxy-X5_eQRq1FckAiaH9NDcu0C1eN_vMxdy0RIhHMwp7alTUSXb2obImxFz-TjNDs--swG_1DXpSRhxUbnxQYOTUbPFl7SorLrZMaEOjbzhlzkFfas6ZNIVsiHFmj5E1CCAq2bkAofPH6Rr7DwAjduBAKiOYkR88swW8esjJKh_BjYHS3VMl1b9fhn2M6_FGXUg86aO2w7xRVBvzxbOc273CWzLYTk74pTT3pzsDFqznSx5EbBTN9MiL3KpgfByU85p31p3VRJ3ca8Kc07cs9SJ8EqCt9hU-JDzFkWyi18JwFBNg-golzqFGQJMIC5M31cALB7-3Y2TT0sR9mcTQ3nSJZefgrnKgWZjG_hVgIFBSNzrXZ8Tz3Bo-YijwGSwlJjjMpC82W_eyU9CvDbv7-qon0rgt-9eXFSUa8dA9DvxndGJAwWSsPL8rKzZv5BFTDuIjux6aWvzD0U7IEUTeB6bc8h4tDgxQa0qUH4ROP3pZ1d4FA";
    config.headers = {
        'Authorization': 'Bearer '+ token,
        'time-offset': -330
    }
    let url = `${process.env.VUE_APP_NODE_IP}/api/v1/`;
    config.baseURL = url;
    return config;
});
export default nodeServiceInstance;
