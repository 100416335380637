import { SET_USER_PROFILE_MUTATION } from "@/store/storeconstants";

export default{
    [SET_USER_PROFILE_MUTATION](state, payload){
        state.id = payload.id;
        state.avatar = payload.avatar;
        state.name = payload.name;
        state.email = payload.email;
        state.phone = payload.phone;
        state.language = payload.language;
        state.role = payload.role;
        state.user_type =payload.user_type;
    },

    setClientRole(state, payload){
        state.role = payload;
    }
}
