<template>
    <div class="registration">
        <div class="reg-title">
            <span>Lets finish your registration</span>
        </div>
        <div class="reg-form">
            <user v-if="register === 'user'" :user_id="id[0]" :user_data="data" @created="$emit('created')" />
            <client v-else-if="register === 'client'" :client_id="id[1]" :client_data="data" @created="$emit('created')"/>
            <template v-else-if="register === 'both'">
                <user v-if="!next" :user_id="id[0]" :user_data="data[0]" @created="next = true" />
                <client v-else :client_id="id[1]" :client_data="data[1]" @created="$emit('created')"/>
            </template>
        </div>
    </div>
</template>

<script>
import User from './User.vue'
import Client from './Client.vue'
export default {
    props: {
        id: {
            required: true,
            type: Array,
        },
        register: {
            required: true,
            type: String
        },
        data: {
            required: true,
            type: Array
        }
    },
    data () {
        return {
            next: false,
        }
    },
    components: {
        User,
        Client
    },
    computed: {
        registering() {
            if(this.register === 'user') return 'user'
            else if (this.register === 'both') {
                if (!this.next) return 'user'
                else return 'client'
            }
            return 'client'
        }
    }
}
</script>

<style scoped>
.registration {
    background: white;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 1px 6px #05152433;
    width: 500px;
    min-height: 20%;
    display: flex;
    flex-direction: column;
}
.registration .reg-title{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 6px #05152433;
}
.registration .reg-title span{
    font-weight: bold;
    text-transform: uppercase;
    color: #051524;
}
</style>