import axiosInstance from '@/services/AxiosTokenInstance'
import api from '@/helpers/api.js'

import {
    CHANGE_USER_PASSWORD,
    GET_USER_PROFILE_ACTION,
    SET_USER_PROFILE_MUTATION,
    UPDATE_USER_PROFILE_ACTION
} from '@/store/storeconstants'

export default {
    async [GET_USER_PROFILE_ACTION](context) {
        let orgData = JSON.parse(localStorage.getItem('organization'));
        const client = localStorage.getItem('client') ? JSON.parse(localStorage.getItem('client')).id : ''
        let response = '';
        try {
            response = await axiosInstance.get(`get-details?org_id=${orgData.id}&client_id=${client}`);
        } catch (error) {
            throw error.response.status;
        }
        if (response.status == 200) {
            let profileResponse = response.data.data;
            let currentRole = profileResponse.user_organizations_role?.user_client_roles?.filter(el => el.id_client === client) || {}
            const responseData = {
                id: profileResponse.id,
                name: profileResponse.name,
                email: profileResponse.email,
                avatar: profileResponse.avatar ? process.env.VUE_APP_DO_SPACES + profileResponse.avatar : '/assets/images/user.png',
                    phone: profileResponse.phone,
                user_type: profileResponse.user_type,
                language: profileResponse.language,
                role: client && currentRole.length ? currentRole[0].role_details : profileResponse.user_organizations_role?.role || {}
            };
            context.commit(SET_USER_PROFILE_MUTATION, responseData);
            localStorage.setItem('U_P', JSON.stringify(responseData));
        }
    },

    async [UPDATE_USER_PROFILE_ACTION](context, payload) {
        // let response = '';
        // let bodyFormData = new FormData();
        // bodyFormData.append('image', payload.avatar);
        // bodyFormData.append('name', payload.name);
        // bodyFormData.append('email', payload.email);
        // bodyFormData.append('phone', payload.phone);
        // bodyFormData.append('language', payload.language);
        // try {
        //     response = await axiosInstance.post('updateprofile', bodyFormData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     });
        // } catch (error) {
        //     throw 'Something went wrong, please try again later';
        // }

        const response = api.post('updateprofile', payload)
        if (response.status == 200) {
            let responseData = {
                name: payload.name,
                email: payload.email,
                phone: payload.phone,
                language: payload.language,
            };
            localStorage.setItem('language', payload.language);
            context.commit(SET_USER_PROFILE_MUTATION, responseData);
        }
    },

    async [CHANGE_USER_PASSWORD](_, payload) {
        let params = {
            old_pass: payload.current_password,
            new_pass: payload.new_password,
            confirm_pass: payload.confirm_password,
        };
        try {
            await axiosInstance.post('change-password', params);
        } catch (error) {
            throw error.response.data.errors;
        }
    }
}