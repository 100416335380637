export default[
  {
      path: "/equipment",
      component: () => import(/* webpackChunkName: "Category" */ "./pages/Equipments/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'equipment'
      }
  },
  {
      path: "/equipment/documents",
      component: () => import(/* webpackChunkName: "Document" */ "./pages/Documents/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'equipment'
      }
  },
  {
      path: "/equipment/faqs",
      component: () => import(/* webpackChunkName: "FAQ" */ "./pages/FAQs/main/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'equipment'
      }
  },
  {
    path: "/equipment/faq-list/:catid",
    component: () => import(/* webpackChunkName: "FAQlist" */ "./pages/FAQs/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'equipment',
      pathing: true
    }
},
{
  path: "/equipment/guides/:catid/step-list/:guideid",
  component: () => import(/* webpackChunkName: "Steplist" */ "./pages/Guides/guides/steps/Index.vue"),
  meta: {
    auth: true,
    layout: 'app-layout',
    organization: true,
    additionalbar : true,
    barlayout: 'main-sidebar',
    main: 'equipment',
    pathing: true,
  }
},
  {
      path: "/equipment/guides/:catid",
      component: () => import(/* webpackChunkName: "Troubleshootings" */ "./pages/Guides/guides/Index.vue"),
      meta: {
        auth: true,
        layout: 'app-layout',
        organization: true,
        additionalbar : true,
        barlayout: 'main-sidebar',
        main: 'equipment',
        pathing: true
      }
  },
  {
    path: "/equipment/guides/:catid/addstep/:guideid",
    component: () => import(/* webpackChunkName: "AddStep" */ "./pages/Guides/guides/steps/AddStep.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'equipment',
      pathing: true
    },
    props: {
      isEdit: false
    }
},
{
  path: "/equipment/guides/:catid/editstep/:stepid",
  component: () => import(/* webpackChunkName: "EditStep" */ "./pages/Guides/guides/steps/AddStep.vue"),
  meta: {
    auth: true,
    layout: 'app-layout',
    organization: true,
    additionalbar : true,
    barlayout: 'main-sidebar',
    main: 'equipment',
    pathing: true
  },
  props: {
    isEdit: true
  }
},
  {
    path: "/equipment/guides",
    component: () => import(/* webpackChunkName: "Guides" */ "./pages/Guides/categories/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'equipment'
    }
},
  {
    path: "/equipment/equipments",
    component: () => import(/* webpackChunkName: "Product" */ "./pages/Equipments/Index.vue"),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'equipment'
    }
  },
  {
    name: 'equipment',
    path: "/equipment/equipments/:prodid",
    component: () => import(/* webpackChunkName: "Product" */ "./pages/Equipments/Details/Index.vue"),
    props: route => ({ tickets: route.params.tickets }),
    meta: {
      auth: true,
      layout: 'app-layout',
      organization: true,
      additionalbar : true,
      barlayout: 'main-sidebar',
      main: 'equipment'
    }
  }
]