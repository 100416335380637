<template>
    <section>
        <div class="pan-container" :class="{opened: isOpen}">
            <div class="slide-btn" @click="isOpen = !isOpen">
                <span v-if="!isMobile()" class="arrow" :class="{opened: isOpen}" />
                <div v-else class="hamburguer" :class="{opened: isOpen}">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div class="side-pan" :class="{fade: isOpen}" v-click-outside="handleOutside">
                <div class="title">
                    {{data.replace('-', ' ')}}
                </div>
                <div class="addiMenuPan">
                    <ul class="navbar-nav">
                        <template v-for="(item,index) in newItems" :key="index">
                            <template v-if="item.roles.includes(getUserProfile.role.id)">
                                <li class="nav-item" :class="{active: isActive === index}" @click="isActive = index">
                                    <router-link :to="item.link" class="nav-link">
                                        <span class="menuIcon">
                                            <i v-if="item.icon" :class="item.icon"></i>
                                            <img v-if="item.image" :src="item.image" :alt="item.name" />
                                        </span>
                                        {{$t(item.name.toLowerCase())}}
                                    </router-link>
                                </li>
                            </template>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import {
    GET_USER_PROFILE_GETTER,
} from '@/store/storeconstants';
import items from '@/assets/json/sidebars.json';
import isMobile from '@/mixins/isMobile'

export default {
    props: {
        data: {
            required: false,
            type: String,
        },
    },

    data() {
        return {
            isOpen: this.check,
            isActive: 0,
            user: localStorage.getItem("U_P") ?  JSON.parse(localStorage.getItem("U_P")) : {}
        };
    },

    mixins: [isMobile],

    watch: {
        data() {
            this.isActive = 0
        },

        $route(to) {
            for (let i = 0; i < this.newItems.length; i++) {
                const element = this.newItems[i];
                if(to.fullPath.includes(element.link)) this.isActive = i
            }
        }
    },

    computed: {
        ...mapGetters('account', {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),
        newItems() {
            return items[this.data]
        },

        check() {
            return this.isMobile() ? false : true
        }
    },

    mounted() {
        for (let i = 0; i < this.newItems.length; i++) {
            const element = this.newItems[i];
            if(element.link === this.$route.fullPath) this.isActive = i
            if(element.relatedPaths) {
                element.relatedPaths.forEach(el => {
                if (this.$route.fullPath.includes(el)) this.isActive = i
            });
            }
        }
    },

    methods: {
        handleOutside() {
            if (this.isMobile()) this.isOpen = false
        }
    }

}
</script>

<style lang="scss" scoped>

section {
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    .pan-container {
        width: 0px;
        position: relative;
        transition: 0.2s;
        height: 100%;
        @media only screen and (max-width: 1200px) {
            height: calc(100% - 5rem);
        }

        &.opened {
            width: 300px;
        }
    }

    

    .slide-btn {
        position: absolute;
        top: 30px;
        right: -15px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        cursor: pointer;
        z-index: 2;

        @media only screen and (max-width: 1200px) {
            right: -40px;
            background: none;
            box-shadow: unset;
        }

        .hamburguer {
            display: flex;
            flex-direction: column;
            gap: .25rem;

            &.opened {
                span {
                    &:first-of-type {
                        width: 20px;
                    }
                }
            }

            span {
                width: 20px;
                height: 2px;
                background: black;
                transition: .2s;

                &:first-of-type {
                    width: 15px;
                }
            }
        }

        .arrow {
            width: 10px;
            height: 10px;
            position: relative;

            &.opened {
                &::before {
                    transform: rotate(-135deg);
                    
                    left: 2.5px;
                }
            }

            &::before {
                position: absolute;
                left: -2.5px;
                content: '';
                width: 10px;
                height: 10px;
                border: solid 2px;
                border-color: black black transparent transparent;
                transform: rotate(45deg);
                transform: .2s;
            }
        }
    }

    .side-pan {
        background-color: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0;
        transition: 0.1s;

        .title {
            margin-top: 15px;
            text-transform: uppercase;
            font-size: 1.5rem;
            padding: 15px 25px;
            font-weight: bold;
            position: relative;
            display: flex;
            align-items: center;
        }

        .addiMenuPan {
            text-transform: capitalize;
        }
    }

    .fade {
        opacity: 1;
    }


    .nav-item .nav-link .menuIcon img {
        filter: grayscale(1);
    }

    .active .nav-link .menuIcon img{
        filter: grayscale(0);
    }
}

</style>