<template>
<div ref="stream" class="streamManager" @click="$emit('update', id)" :class="{sided: (!setMain && isMain !== id), main: (!setMain && isMain === id), default: setMain, hide: (!fs && !popup) && (!setMain && isMain !== id)}" v-if="statedStream && Object.keys(statedStream).length">
	<Video :stream-manager="statedStream" @mounted="handleMounted"/>
	<canvas :id="id"></canvas>
	<div class="name"><p>{{ clientData }}</p></div>
	<div class="initial" v-if="!videoShow && streamManager === myStream">{{ clientData.charAt(0) }}</div>
</div>
</template>

<script>
import Video from './Video';
export default {
	name: 'UserVideo',
	components: {
		Video,
	},
	props: {
		streamManager:{
			required: true,
			type: Object,
		},
		videoShow:{
			required: false,
			type: Boolean,
			default: () => true,
		},
		myStream:{
			required: true,
			type: Object,
		},
		sidebar:{
			required: false,
			type: String,
			default: '',
		},
		isMain: {
			required: false,
			type: String,
			default: '',
		},
		setMain: {
			required: false,
			type: Boolean
		},
		fs: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		popup: {
			required: false,
			type: Boolean
		}
	},

	data(){
		return{
			statedStream: this.streamManager,
			id: ''
		}
	},

	watch: {
		streamManager:{
			immediate: true,
			handler(val) {
				if(val) {
					this.statedStream = val
					setTimeout(() => {
						this.id = this.statedStream.stream.streamId.replace('str_CAM_', '').split('_')[0]
					}, 2000);
				}
			}
		},
		
		fs: {
			immediate: true,
			handler() {
				this.canvasSize()
			}
		},

		isMain: {
			immediate: true,
			handler() {
				this.canvasSize()
			}
		},
	},

	computed: {
		clientData () {
			const { clientData } = this.getConnectionData();
			return clientData;
		},
	},
	methods: {
		getConnectionData () {
			const { connection } = this.streamManager.stream;
			return JSON.parse(connection.data.split('%')[0]);
		},

		canvasSize() {
			setTimeout(() => {
				const video = this.$refs.stream.children[0]
				const canvas = this.$refs.stream.children[1]
				canvas.width = video.clientWidth
				canvas.height = video.clientHeight
			}, 250);
		},

		handleMounted() {
			this.$emit('mounted')
			this.canvasSize()
		}
	},

	mounted() {
		window.addEventListener('resize', () => {
			this.canvasSize()
		})
		setTimeout(() => {
			this.canvasSize()
		}, 5000);
	}
};
</script>

<style lang="scss" scoped>
.streamManager {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
	background: rgb(31, 30, 30);

	&.hide {
		display: none;
	}

	&.main {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&.sided {
		width: 10rem;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		max-height: 20%;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		cursor: pointer;
		z-index: 3;

		.name {
			width: 100%;
			text-align: center;
		}

		.initial {
			width: 50px;
			height: 50px;
			font-size: 2rem;
		}

	}

	canvas {
		position: absolute;
		z-index: 3;
	}
	.name {
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.3);
		color: white;
		padding: 10px;
		border-bottom-right-radius: 5px;
	}

	.initial {
		width: 120px;
		height: 120px;
		color: white;
		background: var(--primary-color);
		border-radius: 50%;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 500;
		font-size: 2.5rem;
	}


}
</style>