export default{
    getDisabled(state){
        return state.notChat
    },
    getCallStatus(state){
        return state.callStarted
    },
    getCallId(state){
        return state.callId
    },
}