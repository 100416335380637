<template>
  <section>
      <main>
        <audio id="audio-ring" controls autoplay hidden>
          <source src="/assets/media/ringtone.mp3" type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
            <figure>
              <img
                :src="
                  callDetails.sentBy.avatar
                    ? imgURL + callDetails.sentBy.avatar
                    : '/assets/images/assign-member-lg.png'
                "
                alt="member"
              />
            </figure>
            <span class="name">{{
              callDetails.sentBy.name
            }}</span>
            <div class="buttons">
              <button
                type="button"
                class="callbtn reject"
                v-on:click="disconnectCall"
              >
                <img
                  src="/assets/images/call-icon-white.svg"
                  alt="Stop Calling"
                />
              </button>
              <button
                type="button"
                class="callbtn accept"
                @click="callReceived"
              >
                <img
                  src="/assets/images/call-icon-white.svg"
                  alt="Start Calling"
                />
              </button>
            </div>
            <form>
              <div class="data">
                <label>Message</label>
                <div>
                  <span><b>{{ message.length }}</b>/{{ textTotalCount }}</span>
                </div>
              </div>
              <textarea
                v-model="message"
                rows="2"
                maxlength="50"
              ></textarea>
              <div class="submit-message">
                <span class="reject" @click="disconnectCall">Submit</span>
              </div>
            </form>
      </main>
  </section>
</template>

<script>
//const someSound = require("../../../public/assets/media/Iphone_Ringtone.mp3");
import { mapMutations } from "vuex";
export default {
  props: ["callDetails"],
  data() {
    return {
      timer: undefined,
      message: "",
      textTotalCount: 50,
      imgURL: process.env.VUE_APP_DO_SPACES,
    };
  },
  mounted() {
    setTimeout(() => {
      this.emitter.emit("call-disconnected", {
        room: this.callDetails.room._id,
        text: '',
      });
    }, 3000000);
    this.playRingtone();
  },

  methods: {
    ...mapMutations({
      setCallStatus: "call/setCallStatus",
      setCallId: "call/setCallId",
    }),
    playRingtone() {
      const audio = document.getElementById("audio-ring");
      audio.play();
    },
    disconnectCall() {
      const params = {
        room: this.callDetails.room._id,
        text: this.message,
      };
      this.emitter.emit("call-disconnected", params);
      this.setCallStatus(false);
      const audio = document.getElementById("audio-ring");
      audio.pause();
      this.$emit("close");
    },
    callReceived() {
      const params = {
        roomId: this.callDetails.room._id,
        with: this.callDetails.sentBy,
      };
      this.emitter.emit("call-received", params);
      this.setCallStatus(true);
      this.setCallId(this.callDetails.room._id);
      const audio = document.getElementById("audio-ring");
      audio.pause();
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
section {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(100,100,100,0.4);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;

    main {
        background: white;
        width: 95%;
        max-width: 300px;
        height: 95%;
        max-height: 500px;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        padding: 20px;
        gap: 20px;

        figure {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          overflow: hidden;
          border: solid 2px var(--primary-color);
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          font-weight: 600;
          font-weight: 1.2rem;
        }

        .buttons {
          display: flex;
          gap: 2rem;

          .callbtn {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            img {
              width: 40%;
              height: 40%;
            }

            &.accept {
              background: var(--primary-color);

              img {
                transform: rotate(180deg);
              }
            }
            &.reject {
              background: $red;

              img {
                transform: rotate(-45deg);
              }
            }
          }
        }

        form {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          width: 100%;

          .data {
            display: flex;
            gap: 0.2rem;
          }
          textarea {
            resize: none;
            border: solid 1px $grey;
            height: 5rem;
            padding: 10px;

            &:focus {
              outline: none;
            }
          }

          .submit-message {
            padding: 10px 25px;
            background: var(--primary-color);
            color: white;
            border-radius: 5px;
            cursor: pointer;
            user-select: none;
            transition: 0.2s;
            display: flex;
            justify-content: center;
            &:hover {
              background: #0072FFc4;
            }
          }
        }
    }
}




</style>