<template>
    <div class="color-picker">
        <div class="predefined">
            <template v-for="clr in color" :key="clr">
                <div class="color" :class="{active: selColor === clr}" :style="{background: clr}" @click="selColor = clr"></div>
            </template>
            <input class="colorInput" type="color" v-model="customColor" :style="{'--bgColor': selColor}">
        </div>
    </div>
</template>

<script>
import colors from '@/assets/json/colors.json'
export default {
    props: {
        initColor: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            color: colors,
            selColor: this.initColor,
            customColor: '#efefef'
        }
    },

    watch: {
        selColor(val) {
            this.$emit('newColor', val)
        },

        customColor(val) {
            this.selColor = val
        }
    }
}
</script>

<style lang="scss" scoped>
.color-picker {
    background: rgba(0,0,0,0.3);
    border-radius: 4px;
    position: absolute;
    bottom: 65px;
    left: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    z-index: 4;
    width: 40px;
    padding: 20px 5px;
    max-height: 50%;
    overflow-y: auto;
    overflow-x: hidden;

    .predefined {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        .color {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            cursor: pointer;
            border: solid 1px transparent;
            overflow: hidden;
            transition: 0.2s;

            &.active {
                border: solid 1px #6f97f5;
            }

            &:hover {
                border: solid 1px #1f4ebd;
            }
        }
    }

    .colorInput {
        width: 25px;
        height: 25px;
        min-height: 25px;
        border-radius: 50%;
        appearance: none;
        background: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &::-webkit-color-swatch {
            opacity: 0;
        }

        &::after {
            content: '';
            border-radius: 50%;
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--bgColor);
            border: solid 3px black;
        }
    }
}

@keyframes clrpck {
    from {
        height: 0px;
        opacity: 0;
    }
    to {
        height: 170px;
        opacity: 1;
    }
}
</style>