// import { i18n } from '../i18n';
import clearMessageFormat from './formatMessage';

const notification = {
  data() {
    return {
      imgURL: process.env.VUE_APP_DO_SPACES,
    }
  },

  mixins: [clearMessageFormat],

  methods: {
    setNotification(user, message, tag) {
      navigator.serviceWorker.ready.then(async ready => {
        ready.showNotification(user.name, {
          actions: [
            {
              action: 'view',
              title: 'See in Browser',
            },
            {
              action: 'mark_as_read',
              title: 'Close',
            },
          ],
          body: this.clearMessageFormat(message),
          badge: this.imgURL + user.avatar,
          icon: this.imgURL + user.avatar,
          tag: `${tag}-${user.name}`,
          renotify: true,
          data: { url: '/chat' },
        });
      });
    },
  },
};

export default notification;
