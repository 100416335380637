<template>
    <main class="mobile">
        <template v-for="(item, i) in staticItems" :key="i">
            <div class="item" :class="{active: currentRoute.includes(item.desktop_endpoint)}" @click="goTo(item.desktop_endpoint)">
                <figure>
                    <img :src="item.desktop_icon" alt="module_icon">
                </figure>
            </div>
        </template>
    </main>
</template>

<script>
import staticItems from '@/assets/json/sidebar.json'

export default {
    
    data() {
        return {
            currentRoute: undefined,
            staticItems
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(val) {
                this.currentRoute = val.path
            }
        }
    },

    methods: {
        goTo(data) {
            this.$router.push(data)
        }
    }

}
</script>

<style lang="scss" scoped>
    .mobile {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 5rem;
        background: $bar;
        z-index: 2;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;

            &.active {
                background: var(--primary-color);

                &:hover {
                    background: var(--primary-color);
                    animation: none;
                }
            }
        }
    }
</style>