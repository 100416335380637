<template>
	<div class="wrapper">
		<sidebar />
		<div class="mainPan" :class="{mobile: isMobile()}">
			<component :is="layout" :data="from"/>
			<div class="bodyPan" id="bodyPan">
				<div class="container-fluid">
					<Header />
					<div class="contentPan">
						<div v-if="hasPathing" class="pathing">
							<div class="path" v-for="(path,index) in pathLinks" :key="index" >
								<template v-if="(index + 1) < pathLinks.length">
									<router-link :to="path.link">
										{{path.name}}
									</router-link>
									<span>></span>
								</template>
								<span v-else>{{path.name}}</span>
							</div>
						</div>
						<slot />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Sidebar from '@/components/Ui/Sidebar/Index.vue';
import isMobile from '@/mixins/isMobile'
// import Sidebar from './Sidebar.vue';
import Header from './Header.vue';
import { mapGetters } from 'vuex'
export default {
	data(){
		return{
			isAdditionalSidebar: this.$route.meta.additionalbar,
			from: this.$route.meta.main,
			hasPathing: false,
		}
	},

    mixins: [isMobile],

	components: {
		Header,
		Sidebar,
	},
    
	computed:{
		layout(){
			return this.$route.meta.additionalbar === true ? this.$route.meta.barlayout : null;
		},
		...mapGetters({
			pathLinks: 'pathing/getPathing',
		}),
        activeOrg() {
            if(window.location.hostname === 'serviceapp.cheto.eu') return 'cheto'
            else if(window.location.hostname === 'remoteassistance.altice-empresas.pt') return 'altice'
            return 'immera'
        }
	},
	watch: {
		$route() {
			this.from = this.$route.meta.main
			this.hasPathing = this.$route.meta.pathing || false
		}
	},

    methods: {
        // ...mapActions({
        //     getAnalytics: 'analytics/getAnalytics',
        // }),
        loadVariables() {
            let cssLink = document.createElement('link')
            cssLink.rel = 'stylesheet';
            if(window.location.hostname === 'serviceapp.cheto.eu') {
                cssLink.href = '/assets/css/_variables-cheto.css'
                document.head.appendChild(cssLink)
            }
            else if(this.activeOrg === 'altice') {
                cssLink.href = '/assets/css/_variables-altice.css'
                document.head.appendChild(cssLink)
            }
        }
    },

    created() {
        this.loadVariables()
    },

	mounted() {
		this.from = this.$route.meta.main
		this.hasPathing = this.$route.meta.pathing || false

        // this.getAnalytics()

        if (!localStorage.getItem('userData')) {
            this.$router.push('/')
        }
        else if (localStorage.getItem('userData') && !localStorage.getItem('organization')) {
            this.$router.push('/')
        }
        else if(localStorage.getItem('organization') && localStorage.getItem('U_P')) {
            const user = JSON.parse(localStorage.getItem('U_P'))
            if (!localStorage.getItem('client') && !user.role.id) this.$router.push('/')
        }
	},

}
</script>

<style lang="scss">
mark {
    border-radius: 3px;
    color: black;
    background-color: #abe5e9;
    padding: 1px;
}

.empty{
	height: 500px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	font-weight: 500;
}
.loading {
	height: 500px;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
}
.loader {
	border: 8px solid #f3f3f3;
	border-radius: 50%;
	border-top: 8px solid var(--primary-color);
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

.contentPan {
    @media only screen and (max-width: 1200px) {
        padding-top: 20px;
    }
}

.bodyPan {
    @media only screen and (max-width: 1200px) {
        padding: 0 10px 6rem;
    }
}


* {
	&::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #f2f5f8;
		border: 2px solid rgba(0, 0, 0, 0);
		background-clip: padding-box;
		border-radius: 9999px;
		transition: 0.2s;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #e6e8eb;
		border: 2px solid rgba(0, 0, 0, 0);
		background-clip: padding-box;
		border-radius: 9999px;
	}
}



@media only screen and (max-height: 900px) and (max-width: 600px) {
	html {
		overflow: hidden;
	}
	body {
		height: 100vh;
	}
	.mainPan {
		width: 100vw;
		overflow: hidden;
	}
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@keyframes move {
  0% {
    opacity: 0;
    transform: translateY(20px);
    overflow: hidden;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    overflow: hidden;
  }
}

@keyframes open {
	0% {
		opacity: 0;
		transform: translateY(-30px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}
</style>

<style lang="scss" scoped>
.pathing {
	display: flex;
	padding: 10px 0;
	gap: 5px;
	user-select: none;
}
.pathing .path {
	display: flex;
	gap: 5px;
	text-transform: capitalize;
	color: gray;
}

.pathing .path a {
	text-decoration: none;
	color: gray;
}
.pathing .path a:hover {
	color: var(--primary-color);
}

blockquote {
	border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
}

button:focus {
	outline: none
}

</style>