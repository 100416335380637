import axios from "axios";
import store from '@/store'
import { GET_USER_TOKEN_GETTER } from "@/store/storeconstants";

const axiosInstance = axios.create({});
axiosInstance.interceptors.request.use((config) => {
    // let params = new URLSearchParams();
    let token = store.getters[`auth/${GET_USER_TOKEN_GETTER}`];
    config.headers = {
        'Authorization': 'Bearer '+token,
        organization : 'default'
    }
    //let url = `http://localhost:8000/api/`;
    let url = process.env.VUE_APP_API_IP;
    //let url = `https://qa.assistance.immera.io/api/`;
    config.baseURL = url;

    // params.append('hello', 'jarves');
    // config.params = params;
    return config;
});

export default axiosInstance;
