import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";

export default{
    [GET_USER_PROFILE_GETTER](state){
        return {
            id: state.id,
            avatar: state.avatar,
            name: state.name,
            email: state.email,
            phone: state.phone,
            language: state.language,
            role: state.role
        };
    }
}