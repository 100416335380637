<template>
    <div class="item">
        <div class="boolean">
            <label :for="item.label">
                <span>{{ item.label }}</span>
                <span v-if="item.required"> *</span>
            </label>
            <span class="message">{{item.message}}</span>
            <div class="buttons">
                <span :class="{active: value === 1}" @click="value = 1">
                    Yes
                </span>
                <span :class="{active: value === 2}" @click="value = 2">
                    No
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object
        }
    },

    data() {
        return {
            value: 2
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.$emit('data', [this.item.name, val])
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    label {
        font-weight: 500;
        font-size: 1rem;
		color: var(--primary-color);
        margin: 0;
    }
    input {
        border: solid 1px #cad6e2;
        border-radius: 2px;
        padding: 10px 15px;
    }

    .item {
        gap: 1rem;
        .boolean {
            display: flex;
            flex-direction: column;
            gap: .5rem;

            .message {
                color: $grey;
            }

            .buttons {
                width: 100%;
                display: flex;
                justify-content: space-between;
                gap: .5rem;
                
                span {
                    width: calc(50% - .5rem);
                    padding: 10px 0;
                    cursor: pointer;
                    border: solid 1px var(--primary-color);
                    background: none;
                    color: var(--primary-color);
                    display: flex;
                    justify-content: center;
                    transition: 0.2s;

                    &.active {
                        color: white;
                        background: var(--primary-color)
                    }
                }; 
            }
        }
    }
</style>