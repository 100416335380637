import getters from "./getters"
import mutations from "./mutations"

export default{
    namespaced: true,
    state(){
        return{
            callStarted:false,
            notChat: false,
            callId: undefined,
        }
    },
    mutations: mutations,
    getters: getters,
}