<template>
	<div class="container">
		<template v-if="!session && !loaded">
			<teleport :disabled="getDisabled" :to="getDisabled ? '#left-header' : '#videochat'">
				<div class="loading" :class="{popup: getDisabled}">
					<img src="/assets/images/ajax-loader.gif" alt="">
					<span>connecting...</span>
				</div>
			</teleport>
		</template>

		<template v-else-if="session">
			<teleport :disabled="tp" :to="tp ? '#header' : '#videochat'">
				<div class="loading" :class="{popup: getDisabled}" v-if="!loaded">
					<img src="/assets/images/ajax-loader.gif" alt="">
					<span>connecting...</span>
				</div>
				<div ref="chat" class="live-chat" :class="{popup: getDisabled, fullscreen: fs}" :style="{ left: (moveChat.left && getDisabled && !fs) ? moveChat.left + 'px' : '', top: (moveChat.top && getDisabled && !fs) ? moveChat.top + 'px' : '', width: (resizer.new_width && getDisabled && !fs) ? resizer.new_width + 'px' : '', height: (resizer.new_height && getDisabled && !fs) ? resizer.new_height + 'px' : ''}">
					<div v-if="getDisabled && !fs" class="dragable" @mousedown="handleOffset" @mousemove="drag ? dragChat($event) : ''" @mouseup="drag = false" ></div>
					<div v-if="getDisabled && !fs" class='resizable'>
						<div ref="res" class='resizers'>
							<div class='resizer top-left' @mousedown="handleDown($event, 'top-left')" @mouseup="handleUp" ></div>
							<div class='resizer top-right' @mousedown="handleDown($event, 'top-right')" @mouseup="handleUp" ></div>
							<div class='resizer bottom-left' @mousedown="handleDown($event, 'bottom-left')" @mouseup="handleUp" ></div>
							<div class='resizer bottom-right' @mousedown="handleDown($event, 'bottom-right')" @mouseup="handleUp" ></div>
						</div>
					</div>
					<div class="main-video" :class="{popup: getDisabled && !fs}">
						<ar-menu v-if="loaded && (fs || !getDisabled)" :room="room" :main="main"  @isDrawing="handleDraw"/>
						<div class="options" v-if="loaded">
							<div class="button" @click="muteAudio">
								<img :src="audioEnabled ? '/assets/images/mic-button.png' : '/assets/images/mic-button-mute.png'" alt="" />
							</div>
							<div class="button" @click="muteVideo">
								<img :src="videoEnabled ? '/assets/images/video-button.png' : '/assets/images/video-button-mute.png'" alt="" />
							</div>
							<div class="button" @click="leaveSession">
								<img src="/assets/images/call-button.png" alt="" />
							</div>
							<div class="button fs" @click="fullscreenVideo">
								<img :src="fs ? '/assets/images/fs_close.svg' : '/assets/images/fs.svg'" alt="" />
							</div>
						</div>
						<div class="video-container" :class="{draw: isDrawing}">
							<user-video :stream-manager="mainStreamManager" :myStream="myStream" :isMain="main" :setMain="setMain" :popup="!getDisabled" :videoShow="videoEnabled" @mounted="loaded = true" @update="updateMainVideoStreamManager"/>
							<template v-for="(sub, index) in subscribers" :key="index">
								<user-video :stream-manager="sub" :myStream="myStream" :isMain="main" :setMain="setMain" :popup="!getDisabled" :fs="fs" :videoShow="videoEnabled" @update="updateMainVideoStreamManager"/>
							</template>
						</div>
					</div>
				</div>
			</teleport>
		</template>
	</div>
</template>

<script>
import axios from 'axios';
import { OpenVidu } from 'openvidu-browser';
import UserVideo from './UserVideo.vue';
import ArMenu from '../AR/Menu.vue';
axios.defaults.headers.post['Content-Type'] = 'application/json';
const OPENVIDU_SERVER_URL = "https://openvidu.assistance.immera.io";
const OPENVIDU_SERVER_SECRET = "3JzYAN37fvmVHJ80";
import { mapActions, mapMutations, mapGetters } from 'vuex';
import {
    CREATE_CHAT_SESSION, LEAVE_CHAT_SESSION
} from '@/store/storeconstants';

export default {
    props: {
        room: {
            required: false,
            type: String,
        },
    },

	components: {
		UserVideo,
		ArMenu
	},

	data () {
		return {
			OV: undefined,
			session: undefined,
			mainStreamManager: undefined,
			publisher: undefined,
			subscribers: [],
			mySessionId: '',
			videoEnabled: true,
			audioEnabled: true,
            user: localStorage.getItem("U_P") ?  JSON.parse(localStorage.getItem("U_P")) : {},
			moveChat: {},
			drag: false,
			onCall: false,
			offset: [],
			isResizing: false,
			resizer: {
			min_size: 200,
			og_width: 0,
			og_height: 0,
			og_x: 0,
			og_y: 0,
			og_mouse_x: 0,
			og_mouse_y: 0,
			new_width: undefined,
			new_height: undefined,
			},
			target: '',
			loaded: false,
			myStream: {},
			othersLogged: 0,
			isDrawing: false,
			tp: true,
			main: '',
			setMain: false,
			fs: false,
		}
	},

	watch: {
		getDisabled: {
			immediate: true,
			handler(val) {
				this.tp = val
			}

		},
		main: {
			immediate: true,
			handler(val) {
				if(!val.length) {
					this.setMain = true
				}
				else this.setMain = false
			}
		},

		mySessionId: {
			immediate: true,
			handler(val) {
				if (val.length) {
					this.emitter.emit('ArConfiguration', {
						roomId: this.room,
						sessionId: val
					})
				}
			}
		}
	},

    computed: {
		...mapGetters({
			getDisabled: 'call/getDisabled',
			getCallId: 'call/getCallId',
		}),
        myUserName() {
            if (this.user) return this.user.name
            return 'Guest' + Math.floor(Math.random() * 100)
        }
    },

	methods: {
        ...mapActions("chat", {
            createChatSession: CREATE_CHAT_SESSION,
            leaveChatSession: LEAVE_CHAT_SESSION,
        }),

		...mapMutations({
			setCallStatus: 'call/setCallStatus',
			setCallId: 'call/setCallId'
		}),

		joinSession () {
			this.OV = new OpenVidu();

			this.session = this.OV.initSession();

			this.session.on('streamCreated', ({ stream }) => {
				const subscriber = this.session.subscribe(stream);
				this.subscribers.push(subscriber);

				if(this.main === '') this.main = subscriber.stream.streamId.replace('str_CAM_', '').split('_')[0]
			});

			this.session.on('streamDestroyed', ({ stream }) => {
				const index = this.subscribers.indexOf(stream.streamManager, 0) || false;
				if (index >= 0) {
					this.subscribers.splice(index, 1);

				}
				this.main = ''
			});

			this.session.on('publisherStartSpeaking', (event) => {
				this.emitter.emit("single-start-speaking", event.connection.data)
			});

			this.session.on('publisherStopSpeaking', (event) => {
				this.emitter.emit("single-stop-speaking", event.connection.data)
			});

			this.session.on('exception', ({ exception }) => {
				console.warn(exception);
			});



			this.session.connect(this.sessionToken, { clientData: this.myUserName })
                .then(() => {
                    this.publisher = this.OV.initPublisher(undefined, {
						audioSource: undefined,
						videoSource: undefined,
						publishAudio: true,
						publishVideo: true,
						resolution: '640x480',
						frameRate: 30,
						insertMode: 'APPEND',
						mirror: false
					});

                    this.mainStreamManager = this.publisher;
                    this.myStream = this.publisher;

                    this.session.publish(this.publisher);
                })
                .catch(error => {
                    console.log('There was an error connecting to the session:', error.code, error.message);
                });
			window.addEventListener('beforeunload', this.leaveSession)
		},
		leaveSession () {
			this.othersLogged = 0
			this.setCallStatus(false)
			const room = this.getCallId
			this.setCallId(undefined)

			if (this.session) this.session.disconnect();

			this.session = undefined;
			this.mainStreamManager = undefined;
			this.publisher = undefined;
			this.subscribers = [];
			this.OV = undefined;
			this.callEnded = true;

			// leave the session on node server
			let params = {
				room_id: room,
				token: this.sessionToken,
			};
			this.leaveChatSession(params)
			.then(() => {
				this.mySessionId = '';
				//this.sessionToken = '';
				console.warn('while leaving', this.sessionToken);
				this.sessionName = '';
			}).catch((error) => {
				console.log(error);
			});

			this.emitter.emit("call-disconnected", {
				room: room,
				text: '',
			});

			window.removeEventListener('beforeunload', this.leaveSession);
			this.$emit('close')
		},

		updateMainVideoStreamManager (id) {
			this.main = id
		},

		getToken (mySessionId) {
			return this.createSession(mySessionId).then(sessionId => this.createToken(sessionId));
		},

		createSession (sessionId) {
			return new Promise((resolve, reject) => {
				axios
					.post(`${OPENVIDU_SERVER_URL}/openvidu/api/sessions`, JSON.stringify({
						customSessionId: sessionId,
					}), {
						auth: {
							username: 'OPENVIDUAPP',
							password: OPENVIDU_SERVER_SECRET,
						},
					})
					.then(response => response.data)
					.then(data => resolve(data.id))
					.catch(error => {
						if (error.response.status === 409) {
							resolve(sessionId);
						} else {
							console.warn(`No connection to OpenVidu Server. This may be a certificate error at ${OPENVIDU_SERVER_URL}`);
							if (window.confirm(`No connection to OpenVidu Server. This may be a certificate error at ${OPENVIDU_SERVER_URL}\n\nClick OK to navigate and accept it. If no certificate warning is shown, then check that your OpenVidu Server is up and running at "${OPENVIDU_SERVER_URL}"`)) {
								location.assign(`${OPENVIDU_SERVER_URL}/accept-certificate`);
							}
							reject(error.response);
						}
					});
			});
		},

		createToken (sessionId) {
			return new Promise((resolve, reject) => {
				axios
					.post(`${OPENVIDU_SERVER_URL}/openvidu/api/sessions/${sessionId}/connection`, {}, {
						auth: {
							username: 'OPENVIDUAPP',
							password: OPENVIDU_SERVER_SECRET,
						},
					})
					.then(response => response.data)
					.then(data => resolve(data.token))
					.catch(error => reject(error.response));
			});
		},

		handleDown(e, position) {
			const rect = this.$refs.res.getBoundingClientRect();
			this.resizer.og_width = rect.width
			this.resizer.og_height = rect.height
			this.resizer.og_x = rect.left
			this.resizer.og_y = rect.top
			this.resizer.og_mouse_x = e.pageX
			this.resizer.og_mouse_y = e.pageY
			this.isResizing = true
			this.target = position
			window.addEventListener('mousemove', this.resizeCall)
		},

		handleUp() {
			this.isResizing = false
			window.removeEventListener('mousemove', this.resizeCall)
		},

		handleDraw(data) {
			this.isDrawing = data
		},

		resizeCall(e) {
		if(this.isResizing) {
			const position = this.target
			switch (position) {
			case 'bottom-right':
				if (this.resizer.og_width + (e.pageX - this.resizer.og_mouse_x) > this.resizer.min_size) this.resizer.new_width = this.resizer.og_width + (e.pageX - this.resizer.og_mouse_x);
				if (this.resizer.og_height + (e.pageY - this.resizer.og_mouse_y) > this.resizer.min_size) this.resizer.new_height = this.resizer.og_height + (e.pageY - this.resizer.og_mouse_y)
				break;
				case 'bottom-left':
				if (this.resizer.og_width - (e.pageX - this.resizer.og_mouse_x) > this.resizer.min_size) {
				this.resizer.new_width = this.resizer.og_width - (e.pageX - this.resizer.og_mouse_x)
				this.moveChat.left = this.resizer.og_x + (e.pageX - this.resizer.og_mouse_x)
				}
				if (this.resizer.og_height + (e.pageY - this.resizer.og_mouse_y) > this.resizer.min_size) this.resizer.new_height = this.resizer.og_height + (e.pageY - this.resizer.og_mouse_y)
				break;
				case 'top-right':
				if (this.resizer.og_width + (e.pageX - this.resizer.og_mouse_x)) this.resizer.new_width = this.resizer.og_width + (e.pageX - this.resizer.og_mouse_x);
				if (this.resizer.og_height - (e.pageY - this.resizer.og_mouse_y) > this.resizer.min_size) {
					this.resizer.new_height = this.resizer.og_height - (e.pageY - this.resizer.og_mouse_y)
					this.moveChat.top = this.resizer.og_y + (e.pageY - this.resizer.og_mouse_y)
				}
				break;
				case 'top-left':
				if (this.resizer.og_width - (e.pageX - this.resizer.og_mouse_x)) {
					this.resizer.new_width = this.resizer.og_width - (e.pageX - this.resizer.og_mouse_x)
					this.moveChat.left = this.resizer.og_x + (e.pageX - this.resizer.og_mouse_x)
				}
				if (this.resizer.og_height - (e.pageY - this.resizer.og_mouse_y) > this.resizer.min_size) {
					this.resizer.new_height = this.resizer.og_height - (e.pageY - this.resizer.og_mouse_y)
					this.moveChat.top = this.resizer.og_y + (e.pageY - this.resizer.og_mouse_y)
				}
				break;
			}
		}
		},

		handleOffset(e) {
			const chat = this.$refs.chat
			this.offset = [
				chat.offsetLeft - e.clientX,
				chat.offsetTop - e.clientY
			]
			this.drag = true
		},

		dragChat(e) {
			if(this.getDisabled) {
				this.moveChat = {
					left: e.clientX + this.offset[0],
					top: e.clientY + this.offset[1]
				}
			}
		},

        async createNewSession(){
            let params = {room_id: this.room};
            await this.createChatSession(params)
            .then((data) => {
                this.mySessionId = data.sessionId;
                this.sessionToken = data.token;
                this.sessionName = data.sessionName;
				this.emitter.emit('setConfigForCall', {
					roomId: this.room,
					sessionId: data.sessionId,
					arStreamerId: '',
					arEnable: false,
					arEnableUserId: 0,
					arEnabledUserName: '',
					drawing: false,
					drawingUserId: 0,
					drawingUserName: '',
					drawingColor: '',
					mainScreenUserId: 0,
					mainScreenUserName: '',
				})
                if(this.mySessionId){
                    this.joinSession();
                }
            }).catch((error) => {
                console.log(error);
            });
        },

		muteVideo(){
			this.videoEnabled = !this.videoEnabled;
			this.emitter.emit('handle-video', this.videoEnabled)
			this.publisher.publishVideo(this.videoEnabled);
		},

		muteAudio(){
			this.audioEnabled = !this.audioEnabled;
			this.emitter.emit('handle-audio', this.audioEnabled)
			this.publisher.publishAudio(this.audioEnabled);
		},

		fullscreenVideo() {
			this.fs = !this.fs
		}
	},

    mounted() {
        this.createNewSession()
    },

	created() {
		this.emitter.on("handle-video", (value) => {
            this.videoEnabled = value
            this.publisher?.publishVideo(this.videoEnabled);
        })
        this.emitter.on("handle-audio-pop", (value) => {
            this.audioEnabled = value
            this.publisher?.publishAudio(this.audioEnabled);
        })
		this.emitter.on("destroy-call", () => {
            this.leaveSession();
        })
	}
}
</script>

<style lang="scss" scoped>
.loading {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	&.popup {
		width: 200px;
		height: 200px;
		background: black;
	}
	img {
		width: 20%;
		object-fit: contain;
	}
	span {
		color: white;
		text-transform: capitalize;
	}
}
.live-chat {
	position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

	&.fullscreen {
		position: fixed !important;
		width: calc(100% - 8rem) !important;
		height: 100% !important;
		top: 0;
		left: 8rem;
		z-index: 3;
		background: grey;

		.streamManager {
			border-radius: 0px;
		}
	}

	&.popup {
		width: auto;
		height: 200px;
		position: absolute;
		z-index: 10;
		align-items: center;
		background: black;
		overflow: hidden;

		.dragable {
			width: 95%;
			height: 95%;
			z-index: 9;
			position: absolute;
			cursor: move;
		}
		.resizable {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 8;
			.resizers{
				width: 100%;
				height: 100%;
				box-sizing: border-box;

				.resizer{
					width: 30px;
					height: 30px;
					position: absolute;
					opacity: 0;
					user-select: none;

					&.top-left {
						left: -15px;
						top: -15px;
						cursor: nwse-resize; /*resizer cursor*/
					}

					&.top-right {
						right: -15px;
						top: -15px;
						cursor: nesw-resize;
					}

					&.bottom-left {
						left: -15px;
						bottom: -15px;
						cursor: nesw-resize;
					}

					&.bottom-right {
						right: -15px;
						bottom: -15px;
						cursor: nwse-resize;
					}
				}
			}
		}
	}
	.main-video {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 420px;

		&.popup {
			min-height: 200px;
			min-width: 200px;
			.options {
				width: unset;
				z-index: 10;
				.button {
					width: 2rem;
					&.fs {
						width: 2rem;
						height: 2rem;
					}
				}
			}
		}
		.options {
			position: absolute;
			bottom: 0;
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			z-index: 7;
			padding: 10px;
			&.draw {
				z-index: 2;
			}
			.button {
				cursor: pointer;
				&.fs {
					width: 50px;
					height: 50px;
					background: rgba(100,100,100,0.3);
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						width: 40%;
					}
				}
			}
		}
		.video-container {
			padding: 5px;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			gap: 5px;
			&.draw {
				z-index: 2;
			}
		}
	}
}
</style>