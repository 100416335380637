<template>
    <section @click="goToSettings">settings</section>
</template>

<script>
export default {
    methods: {
        goToSettings() {
            this.$router.push('/company-settings')
        }
    }
}
</script>

<style lang="scss" scoped>
    section {
        position: absolute;
        bottom: 0;
        color: white;
        padding: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        cursor: pointer;
        user-select: none;
        font-size: 0.6rem;
        transition: 0.2s;

        

        @media only screen and (max-width: 768px) {
            position: inherit;
        }

        &:hover {
            background: rgba(255,255,255,0.2);
        }
    }
</style>