import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { IS_USER_AUTHENCATED } from "@/store/storeconstants";

import HomeRoutes from '../views/home/routes';
import ChatRoutes from '../views/Chat/routes';
import LoginRoutes from '../views/login/routes';
import RegisterRoutes from '../views/registration/routes';
import OrganizationsRoutes from '../views/organizations/routes';
import DashboardRoutes from '../views/dashboard/routes';
import EquipmentRoutes from '../views/equipment/routes';
import DataStructureRoutes from '../views/data_structure/routes';
import RemoteSupportRoutes from '../views/remote_support/routes';
import PerformanceRoutes from '../views/performance/routes';
import SettingsRoutes from '../views/Settings/routes';

const NotFound = () => import(/* webpackChunkName: "NotFound" */ "../components/NotFound.vue");

const routes = [
  ...HomeRoutes,
  ...LoginRoutes,
  ...OrganizationsRoutes,
  ...DashboardRoutes,
  ...EquipmentRoutes,
  ...DataStructureRoutes,
  ...RemoteSupportRoutes,
  ...PerformanceRoutes,
  ...RegisterRoutes,
  ...ChatRoutes,
  ...SettingsRoutes,
  {
    path: '/:NotFound(.*)*',
    component: NotFound,
    meta: {
      layout: 'auth-layout'
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let organizationData = localStorage.getItem('organization');
  let clientData = localStorage.getItem('client');
  if (
    "auth" in to.meta &&
    to.meta.auth &&
    !store.getters[`auth/${IS_USER_AUTHENCATED}`]
  ) {
    next("/");
  } else if (
    "auth" in to.meta &&
    !to.meta.auth &&
    store.getters[`auth/${IS_USER_AUTHENCATED}`]
  ) {
    // redirecting to dashboard page
    if(organizationData || clientData){
      next("/data-structure");
    }else{
      next("/")
    }
  } else if (
    "auth" in to.meta &&
    to.meta.auth &&
    store.getters[`auth/${IS_USER_AUTHENCATED}`] &&
    to.meta.organization
  ) {
    // redirecting to dashboard page
    if(organizationData || clientData){
      next();
    }else{
      next("/")
    }
  } else {
    next();
  }
});

export default router;
