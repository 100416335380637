<template>
    <section>
        <template v-for="(item, i) in staticModules" :key="i">
            <div class="item" :class="{active: currentRoute.includes(item.desktop_endpoint)}" @click="goTo(item.desktop_endpoint)">
                <div class="data">
                    <figure>
                        <img :src="item.desktop_icon" alt="module_icon">
                    </figure>
                    <span>{{item.name}}</span>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import staticItems from '@/assets/json/sidebar.json'
import axiosInstance from '@/services/AxiosTokenInstance'
export default {
    data() {
        return {
            imgURL: process.env.VUE_APP_DO_SPACES,
            availableModules: [],
            currentRoute: undefined,
            staticModules: staticItems
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(val) {
                this.currentRoute = val.path
            }
        }
    },

    methods: {
        async getModules() {
            const response = await axiosInstance.get('modules-list')
            const data = response.data.data
            const chat = data[0]
            data.splice(0,1)
            data.splice(3,0,chat)
            this.availableModules = response.data.data
        },

        goTo(data) {
            this.$router.push(data)
        }
    },
}
</script>

<style lang="scss" scoped>
    section {
        display: flex;
        flex-direction: column;
        .item {
            color: white;
            cursor: pointer;
            user-select: none;

            &.active {
                background: var(--primary-color);

                &:hover {
                    background: var(--primary-color);
                    animation: none;
                }
            }

            &:hover {
                background: linear-gradient(to right, white 0%, white 5%, #1d2c3c 6%, #1d2c3c 100%);
                background-size: 120% 120%;
                animation: clrpck 0.2s forwards;
            }

            .data {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 1rem 1.5rem;
                gap: 0.5rem;
                @media only screen and (max-width: 768px) {
                    padding: 1rem;
                }

                span {
                    font-size: 0.5rem;
                    text-transform: uppercase;
                    text-align: center;

                    @media only screen and (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
    }
    @keyframes clrpck {
        0% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
</style>