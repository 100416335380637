export default[
    { 
        path: "/dashboard", 
        component: () => import(/* webpackChunkName: "Dashboard" */ "./pages/Dashboard.vue"), 
        meta: { 
          auth: true, 
          layout: 'app-layout',
          organization: true,
          additionalbar : false,
          barlayout: 'data-structure-sidebar'
        } 
      }
]