import axiosInstance from '@/services/AxiosTokenInstance';
const api = {
	data() {
		return{
			apis: {
				sites: 'new-site-list',
				users: 'get-users',
				client_users: 'get-users',
				org_users: 'get-users',
				products: 'product',
				clients: 'new-org-client-list',
				categories: 'category',
				categoryQuestions: 'category-question',
				typology: 'typology',
                'parent-types': 'parent-types',
                'child-types': 'child-types'
			},
			imgURL: process.env.VUE_APP_DO_SPACES,
		}
	},
	methods: {
		forSites(data) {
			return data.map(item => {
				return {id: item.id, name: item.name, image: null, products: item.product_list.filter(el => el.products_details).map(el => {
					return {id: el.products_details.id, name: el.products_details.name, image: null }
				})}
			}) || []
		},

		forProducts(data) {
			return data.map(prod => {
				return {
					id: prod.id,
					name: prod.name,
					image: prod.product_images.length ?
						this.imgURL + prod.product_images[0].image
						: prod.categories.length ?
							prod.categories[0].images.length ?
								this.imgURL + prod.categories[0].images[0].image
								: null
							: null,
					category: prod.categories.length ? prod.categories[0] : {}
				}
            }) || []
		},

		forUsers(data) {
			return data.map(user => {
                return {id: user.user.id, name: user.user.name, image: user.user.avatar ? this.imgURL + user.user.avatar : null}
            }) || []
		},

		forClients(data) {
			return data.map(client => {
                return {id: client.id, name: client.name, image: client.logo ? this.imgURL + client.logo : null}
            }) || []
		},

		forCategories(data) {
			return data.map(cat => {
				return {id: cat.id, name: cat.name, image: cat.images.length ? this.imgURL + cat.images[0].image : null}
			}) || []
		},

		forQuestions(data) {
			return data.map(q => {
				return {template: q.input_type, label: q.question, name: q.question.toLowerCase().replaceAll(' ', '_'), options: q.answers, required: q.is_mandatory}
			}) || []
		},

		async api(data, extra) {
            
			let orgData = JSON.parse(localStorage.getItem('organization'));
			let allData = []
			let lastPage = 1
			let response

			const client = localStorage.getItem("client") ?  JSON.parse(localStorage.getItem("client")) : null
			const client_id = client ? client.id : ''

			const isPromise = (promise) => {
				return !!promise && typeof promise.then === "function";
			};


			if (isPromise(data)) {
				Promise.resolve(data).then((value) => {
					allData.push(...value)
				});
			}
			else if (data === 'client_users') {
				response = await axiosInstance.get(`${this.apis[data]}?org_id=${orgData.id}&clientId=${client_id}&type=clients&limit=100`);

				lastPage = response.data.data.last_page
				allData.push(...response.data.data.data)

				if(lastPage >= 2) {
					for (let i = 2; i <= lastPage; i++) {
						const res = await axiosInstance.get(`${this.apis[data]}?org_id=${orgData.id}&clientId=${client_id}&type=clients&limit=100&page=${i}`);
						allData.push(...res.data.data.data)
					}
				}
			}
			else if (data === 'org_users') {
				response = await axiosInstance.get(`${this.apis[data]}?org_id=${orgData.id}&clientId=${client_id}&type=users&searchKey=${extra}&limit=100`);

				lastPage = response.data.data.last_page
				allData.push(...response.data.data.data)

				if(lastPage >= 2) {
					for (let i = 2; i <= lastPage; i++) {
						const res = await axiosInstance.get(`${this.apis[data]}?org_id=${orgData.id}&clientId=${client_id}&type=users&searchKey=${extra}&limit=100&page=${i}`);
						allData.push(...res.data.data.data)
					}
				}
			}
			else if (data === 'categoryQuestions') {
				response = await axiosInstance.get(`${this.apis[data]}?org_id=${orgData.id}&clientId=${client_id}&category_id=${extra[0]}&question_type=${extra[1]}&limit=100`);

				lastPage = response.data.data.last_page
				allData.push(...response.data.data.data)

				if(lastPage >= 2) {
					for (let i = 2; i <= lastPage; i++) {
						const res = await axiosInstance.get(`${this.apis[data]}?org_id=${orgData.id}&clientId=${client_id}&category_id=${extra[0]}&question_type=${extra[1]}&limit=100`);
						allData.push(...res.data.data.data)
					}
				}
			}
			else if (data === 'parent-types') {
				response = await axiosInstance.get(`typology?org_id=${orgData.id}&clientId=${client_id}&parent=1&limit=100`);

				lastPage = response.data.data.last_page
				allData.push(...response.data.data.data)

				if(lastPage >= 2) {
					for (let i = 2; i <= lastPage; i++) {
						const res = await axiosInstance.get(`typology?org_id=${orgData.id}&clientId=${client_id}&parent=1&limit=100`);
						allData.push(...res.data.data.data)
					}
				}
			}
			else if (data === 'child-types') {
				response = await axiosInstance.get(`typology?org_id=${orgData.id}&child=1&limit=100`);

				lastPage = response.data.data.last_page
				allData.push(...response.data.data.data)

				if(lastPage >= 2) {
					for (let i = 2; i <= lastPage; i++) {
						const res = await axiosInstance.get(`typology?org_id=${orgData.id}&child=1&limit=100`);
						allData.push(...res.data.data.data)
					}
				}

                if (extra) allData = allData.filter(el => el.typology_id === extra[1])
			}
			else if (data !== 'countries') {
				if(!extra) response = await axiosInstance.get(`${this.apis[data]}?org_id=${orgData.id}&clientId=${client_id}&limit=100`);
				else response = await axiosInstance.get(`${this.apis[data]}?org_id=${orgData.id}&clientId=${client_id}&${extra[0]}=${extra[1]}&limit=100`);

				lastPage = response.data.data.last_page
				allData.push(...response.data.data.data)

				if(lastPage >= 2) {
					for (let i = 2; i <= lastPage; i++) {
						const res = await axiosInstance.get(`${this.apis[data]}?org_id=${orgData.id}&clientId=${client_id}&limit=100&page=${i}`);
						allData.push(...res.data.data.data)
					}
				}
			}
			else response = await axiosInstance.get(`countries-list`);

			switch (data) {
				case 'sites':
					return this.forSites(allData)
				case 'products':
					return this.forProducts(allData, extra)
				case 'users':
					return this.forUsers(allData)
				case 'client_users':
					return this.forUsers(allData)
				case 'org_users':
					return this.forUsers(allData)
				case 'clients':
					return this.forClients(allData)
				case 'categories':
					return this.forCategories(allData)
				case 'countries':
					return response.data.data
				case 'categoryQuestions':
					return this.forQuestions(allData)
				default:
					return allData
			}
		},
	},
};

export default api;
