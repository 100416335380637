<template>
  <div class="headerPan">
    <one-video-chat v-if="isInitiateCall" :room="callerData.room._id" />

    <div class="leftHeader">
      <div id="header"></div>
    </div>

    <div class="rightHeader">
      <ul>
        <li v-if="isInitiateCall" class="call-holder">
          <div class="call-info">
            <div class="usr-info" :class="{ speaking: isSpeaking }">
              <figure>
                <img
                  :src="
                    callerData.sentBy.avatar
                      ? imgURL + callerData.sentBy.avatar
                      : '/assets/images/assign-member-lg.png'
                  "
                  alt=""
                />
              </figure>
              <div class="userDtls">
                <span
                  class="name"
                  v-if="userLogged.id !== callerData.sentBy.id"
                  >{{ userLogged.name }}</span
                >
                <span class="name" v-else>{{ callerData.sentBy.name }}</span>
                <span class="desig">Technician</span>
              </div>
            </div>
            <div class="options">
              <div class="small-options">
                <span
                  :class="{ muted: !videoEnabled }"
                  @click="videoToggle"
                  title="Toggle Camera"
                >
                  <img class="cam" src="/assets/images/vid.svg" />
                </span>
                <span
                  :class="{ muted: !audioEnabled }"
                  @click="audioToggle"
                  title="Toggle Microphone"
                >
                  <img src="/assets/images/mic.svg" />
                </span>
                <span class="end-call" @click="destroyCall" title="End Call">
                  <img src="/assets/images/call-icon-white.svg" />
                </span>
              </div>
              <div class="btc" @click="goToCall" title="Go Back to Call"></div>
            </div>
          </div>
        </li>
        <li class="notification">
          <section>
            <div class="icons" @click="notifications = !notifications">
              <img src="/assets/images/notif.svg" alt="">
              <span class="count">{{
                totalNotifs || notifs.filter((el) => !el.read).length
              }}</span>
            </div>
            <notifications
              v-if="notifications"
              :notifications="notifs"
              @close="notifications = false"
              @loadMore="handleNotifPage"
              @getUnreads="getUnreadNotifs"
            />
          </section>
        </li>
        <UserTab :user="userProfile" @logout="onLogout"/>
      </ul>
    </div>
  </div>
  <IncomingCall
    v-if="isIncommingCallVisible"
    :callDetails="callerData"
    @close="isIncommingCallVisible = false"
  />
  <transition name="move">
    <alert v-if="alerts.length" :alerts="alerts" />
  </transition>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { io } from "socket.io-client";
import {
  GET_USER_TOKEN_GETTER,
  LOGOUT_USER,
  GET_USER_PROFILE_ACTION,
  GET_USER_PROFILE_GETTER,
} from "@/store/storeconstants";
import IncomingCall from "@/components/Ui/Call/IncomingCall.vue";
import Alert from "@/components/Ui/General/Alert.vue";
import Notifications from "@/components/Ui/General/Notifications.vue";
import OneVideoChat from "@/views/Chat/Video/Index.vue";
import UserTab from '@/components/UserTab/Index.vue'
import store from "@/store";
import setNotification from "@/mixins/notifications";
import nodeInstance from "@/services/NodeServiceinstance";

export default {
  data() {
    return {
      isProfileModalVisible: false,
      isInitiateCall: false,
      isPasswordModalVisible: false,
      isIncommingCallVisible: false,
      organization_details: undefined,
      socketConnection: undefined,
      callerData: {},
      user: {},
      onCall: false,
      isShowing: false,
      imgURL: process.env.VUE_APP_DO_SPACES,
      isSpeaking: false,
      videoEnabled: true,
      audioEnabled: true,
      currentRoom: "",
      userLogged: localStorage.getItem("U_P")
        ? JSON.parse(localStorage.getItem("U_P"))
        : {},
      alerts: [],
      notifs: [],
      notifications: false,
      notificationsMap: new Map(),
      totalNotifs: 0,
      notifPage: 1,
    };
  },

  mixins: [setNotification],

  components: {
    IncomingCall,
    OneVideoChat,
    Alert,
    Notifications,
    UserTab
  },
  computed: {
    ...mapGetters({
      getDisabled: "call/getDisabled",
      getCallId: "call/getCallId",
    }),
    ...mapGetters("account", {
      userProfile: GET_USER_PROFILE_GETTER,
    }),
  },

  created() {
    // notif service worker
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/sw.js");
    }

    this.organization_details = JSON.parse(
      localStorage.getItem("organization")
    );

    //Normal Event Listeners start here

    this.emitter.on("create-room", (data) => {
      this.createRoom(data);
    });
    this.emitter.on("join-room", (data) => {
      this.joinRoom(data);
    });
    this.emitter.on("request-to-join-call", (data) => {
      this.requestToJoinCall(data);
    });
    this.emitter.on("send-message", (data) => {
      this.sendMessage(data);
    });
    this.emitter.on("read-message", (data) => {
      this.readMessage(data);
    });
    this.emitter.on("is-typing", (data) => {
      this.isTyping(data);
    });
    this.emitter.on("add-participants-to-room", (data) => {
      this.addParticipantsToRoom(data);
    });
    this.emitter.on("remove-participant-from-room", (data) => {
      this.removeParticipantFromRoom(data);
    });
    this.emitter.on("leave-room", (data) => {
      this.leaveRoom(data);
    });
    this.emitter.on("update-room-name", (data) => {
      this.updateRoomName(data);
    });
    this.emitter.on("update-room-image", (data) => {
      this.updateRoomImage(data);
    });
    // disconnect call event
    this.emitter.on("call-disconnected", (data) => {
      this.isIncommingCallVisible = false;
      this.isInitiateCall = false;
      this.rejectCall(data);
    });
    // receive call event
    this.emitter.on("call-received", (data) => {
      this.isIncommingCallVisible = true;
      this.emitter.emit("prepare-video-section");
      this.emitter.emit("video-section-prepared", data);
    });
    this.emitter.on("video-section-prepared", (data) => {
      this.isInitiateCall = true;
      this.initiateCall();
      if (data) {
        this.callerData = {
          room: {
            _id: data.roomId,
          },
          sentBy: data.with,
        };
      }
    });

    this.emitter.on("single-start-speaking", (data) => {
      const userSpeaking = JSON.parse("[" + data.replace("%/%", ",") + "]");
      if (this.callerData.id === userSpeaking.userId) this.isSpeaking = true;
    });
    this.emitter.on("single-stop-speaking", (data) => {
      const userSpeaking = JSON.parse("[" + data.replace("%/%", ",") + "]");
      if (this.callerData.id === userSpeaking.userId) this.isSpeaking = false;
    });

    this.emitter.on("handle-video", (value) => {
      this.videoEnabled = value;
    });
    this.emitter.on("handle-audio", (value) => {
      this.audioEnabled = value;
    });

    this.emitter.on("delete-chats", (data) => {
      this.deleteChats(data);
    });

    this.emitter.on("live-draw", (data) => {
      this.liveDraw(data);
    });
    this.emitter.on("ar-draw", (data) => {
      this.arDraw(data);
    });

    this.emitter.on("logout", () => {
      this.socketConnection.disconnect();
    });

    this.emitter.on("alert", (data) => {
      this.alerts.push(data);
    });
    this.emitter.on("ArConfiguration", (data) => {
      this.socketConnection.emit("getArConfiguration", JSON.stringify(data));
    });
    this.emitter.on("setConfigForCall", (data) => {
      this.socketConnection.emit("setArConfiguration", JSON.stringify(data));
    });
    //Normal Event Listeners end here

    //Start socket connection
    this.createSocketConnection();
    /**
     * Socket listeners start here
     */
    // On Socket connection established
    this.socketConnection.on("connected", (data) => {
      console.warn("Socket connection established", data);
    });
    // On Room Created Event
    this.socketConnection.on("roomCreated", (data) => {
      this.emitter.emit("room-created", data);
    });
    // On Room Joined Event
    this.socketConnection.on("roomJoined", (data) => {
      this.emitter.emit("room-joined", data);
    });
    // On Requested To Join Room Event
    this.socketConnection.on("requestedToJoinRoom", (data) => {
      this.emitter.emit("requested-to-join-room", data);
    });
    // On User Typing Event
    this.socketConnection.on("typing", (data) => {
      this.emitter.emit("user-typing", data);
    });
    // On Receive New Message Event
    this.socketConnection.on("newMessage", (data) => {
      if (
        data.sentBy &&
        data.sentBy.userId !== this.user.id &&
        data.type !== "joinCall"
      ) {
        this.setNotification(data.sentBy, data.text, "new-message");
        this.notifs.splice(0, 0, {
          type: "message",
          title: `${data.sentBy.name} sent you a message.`,
          to: `/chat?roomId=${data._id}`,
          read: false,
        });
      }
      this.emitter.emit("new-message", data);
    });
    // On Receive Call Event
    this.socketConnection.on("callRing", (data) => {
      console.warn("the incoming call", data);
      if (data.receiverUsers.some((e) => e.userId === this.user.id)) {
        this.callerData = data;
        this.isIncommingCallVisible = true;
      }
    });
    // On Participants Added To Room Event
    this.socketConnection.on("participantsAddedToRoom", (data) => {
      this.emitter.emit("participants-added-to-room", data);
    });
    // On Participant Removed From Room Event
    this.socketConnection.on("participantRemovedFromRoom", (data) => {
      this.emitter.emit("participant-removed-from-room", data);
    });
    // On Left Room
    this.socketConnection.on("leftRoom", (data) => {
      console.warn("left-room", data);
    });
    // On room name update
    this.socketConnection.on("roomNameUpdated", (data) => {
      this.emitter.emit("room-name-updated", data);
    });
    // On room image updated
    this.socketConnection.on("roomImageUpdated", (data) => {
      this.emitter.emit("room-image-updated", data);
    });
    // On Disconnect Event
    this.socketConnection.on("disconnected", (data) => {
      console.warn("disconnected", data);
      //this.socketConnection.connect();
    });
    this.socketConnection.on("chatsDeleted", (data) => {
      this.emitter.emit("chats-deleted", data);
    });
    this.socketConnection.on("messageRead", (data) => {
      this.emitter.emit("message-read", data);
    });
    this.socketConnection.on("liveDrawing", (data) => {
      this.emitter.emit("receive-live-draw", data);
    });
    this.socketConnection.on("setArConfig", (data) => {
      this.emitter.emit("receive-ar-config", data);
    });
    /**
     * Socket listeners end here
     */
  },
  methods: {
    ...mapActions("auth", {
      logout: LOGOUT_USER,
    }),
    ...mapActions("account", {
      getUserData: GET_USER_PROFILE_ACTION,
    }),
    ...mapMutations({
      setDisabled: "call/setDisabled",
    }),
    onLogout() {
      this.logout();
      this.socketConnection.disconnect();
      this.$router.replace("/");
    },
    showProfileModal() {
      this.isProfileModalVisible = true;
    },
    closeProfileModal() {
      this.isProfileModalVisible = false;
    },
    showPasswordModal() {
      this.isPasswordModalVisible = true;
    },
    closePasswordModal() {
      this.isPasswordModalVisible = false;
    },

    audioToggle() {
      this.audioEnabled = !this.audioEnabled;
      this.emitter.emit("handle-audio-pop", this.audioEnabled);
    },

    videoToggle() {
      this.videoEnabled = !this.videoEnabled;
      this.emitter.emit("handle-video", this.videoEnabled);
    },

    destroyCall() {
      this.isShowing = false;
      this.isIncommingCallVisible = false;
      this.isInitiateCall = false;
      this.emitter.emit("destroy-call");
    },

    // openvidu call related methods
    initiateCall() {},

    async getNotifications(page) {
      const nots = await nodeInstance.get(
        `/notifications/list?organizationId=2&page=${page}&limit=50`
      );

      this.getUnreadNotifs();

      const notifs = nots.data.notificationList.map((not) => {
        return {
          type: not.type,
          title: not.title,
          to: `/chat?roomId=${not._id}`,
          read: not.isRead,
          id: not._id,
        };
      });

      this.notifs.push(...notifs);
    },

    handleNotifPage() {
      if (this.totalNotifs / 3 > this.notifPage) this.notifPage += 1;
    },

    async getUnreadNotifs() {
      const unreads = await nodeInstance.get(
        `/notifications/unreadCount?organizationId=2`
      );
      this.totalNotifs = unreads.data.unreadNotificationCount;
    },

    /*
     * All socket related events start here
     */
    // Initiate Socket Connection
    createSocketConnection() {
      let authToken = store.getters[`auth/${GET_USER_TOKEN_GETTER}`];
      const options = {
        auth: {
          token: authToken,
        },
        query: {
          timeOffset: "-330",
        },
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99999,
      };
      this.socketConnection = io(process.env.VUE_APP_NODE_IP, options);
    },

    // Create Room
    createRoom(data) {
      const params = {};
      params.organizationId = data.organizationId;
      params.type = data.type;

      if (data.ticketId) {
        params.ticketId = data.ticketId;
      }
      if (data.participants) {
        params.participants = data.participants;
      }
      if (data.groupImage) {
        params.image = data.groupImage;
      }
      if (data.groupName) {
        params.name = data.groupName;
      }
      console.warn("create group params", params);
      this.socketConnection.emit("createRoom", JSON.stringify(params));
    },
    // Join Room
    joinRoom(data) {
      const params = {};
      if (data.roomId) {
        params.room = data.roomId;
      }
      if (data.ticketId) {
        params.ticketId = data.ticketId;
      }
      this.socketConnection.emit("joinRoom", JSON.stringify(params));
    },
    // Request to join room
    requestToJoinRoom(data) {
      const params = {
        room: data.roomId,
      };
      this.socketConnection.emit("requestedToJoinRoom", JSON.stringify(params));
    },
    //Request to join call on chat Event
    requestToJoinCall(data) {
      const params = {
        room: data.roomId,
      };
      this.socketConnection.emit("requestToJoinCall", JSON.stringify(params));
    },
    //User Typing Event
    isTyping(data) {
      const params = {
        room: data.roomId,
      };
      this.socketConnection.emit("isTyping", JSON.stringify(params));
    },
    //Send Message Event
    sendMessage(data) {
      const params = {};
      params.room = data.roomId;
      params.text = data.text;
      if (data.parentId) params.parentId = data.parentId;
      if (data.mentionedUsers) params.mentionedUsers = data.mentionedUsers
      this.socketConnection.emit("sendMessage", JSON.stringify(params));
    },
    //Read Message Event
    readMessage(data) {
      const params = {
        room: data.room,
        chatIds: data.chatIds,
      };
      this.socketConnection.emit("readMessage", JSON.stringify(params));
    },
    //Add participants to a room/group
    addParticipantsToRoom(data) {
      const params = {
        room: data.roomId,
        participants: data.participantIds,
      };
      this.socketConnection.emit(
        "addParticipantsToRoom",
        JSON.stringify(params)
      );
    },
    //Remove a participant from a room/group
    removeParticipantFromRoom(data) {
      const params = {
        room: data.roomId,
        userId: data.userId,
      };
      this.socketConnection.emit(
        "removeParticipantFromRoom",
        JSON.stringify(params)
      );
    },
    //Leave a room/group
    leaveRoom(data) {
      const params = {
        room: data.roomId,
      };
      this.socketConnection.emit("leaveRoom", JSON.stringify(params));
    },
    // Update room name
    updateRoomName(data) {
      const params = {
        room: data.roomId,
        name: data.name,
      };
      this.socketConnection.emit("updateRoomName", JSON.stringify(params));
    },
    // Update room image
    updateRoomImage(data) {
      const params = {
        room: data.roomId,
        image: data.image,
      };
      this.socketConnection.emit("updateRoomImage", JSON.stringify(params));
    },
    /**
     * All socket related events end here
     */

    goToCall() {
      this.$router.push({ path: "/chat", query: { roomId: this.getCallId } });
    },

    rejectCall(data) {
      this.socketConnection.emit("rejectCall", JSON.stringify(data));
    },

    deleteChats(data) {
      this.socketConnection.emit("deleteChats", JSON.stringify(data));
    },

    liveDraw(data) {
      this.socketConnection.emit("liveDraw", JSON.stringify(data));
    },
    arDraw(data) {
      this.socketConnection.emit("markArPoint", JSON.stringify(data));
    },
  },

  watch: {
    $route() {
      this.setDisabled(true);
    },

    getDisabled(val) {
      this.isShowing = val;
    },

    alerts: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.alerts.splice(this.alerts.length - 1, 1);
        }, 3000);
      },
    },

    notifPage: {
      immediate: true,
      handler(val) {
        this.getNotifications(val);
      },
    },
  },

  mounted() {
    if (localStorage.userData) {
      let userDataString = localStorage.getItem("U_P");
      if (userDataString) {
        this.user = JSON.parse(userDataString);
      }
    }
    this.emitter.on("update-user", () => {
      let userDataString = localStorage.getItem("U_P");
      if (userDataString) {
        this.user = JSON.parse(userDataString);
      }
    });

    window.onbeforeunload = () => {
      this.socketConnection.disconnect();
    };

    this.getUserData();

    if (
      "Notification" in window &&
      (Notification.permission !== "denied" ||
        Notification.permission !== "default")
    ) {
      Notification.requestPermission();
    }
  },
};
</script>
<style lang="scss" scoped>
.move-enter-active {
  animation: move 0.2s;
}
.move-leave-active {
  animation: move 0.2s reverse;
}
ul {
  position: relative;
}
.call-holder {
  position: absolute;
  top: 0;
  left: -400px;
}
.call-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgba(255, 255, 255, 0.8);
  padding: 15px;
  border-radius: 15px;
  width: 350px;
}
.call-info .usr-info {
  display: flex;
  align-items: center;
  gap: 5px;
}
.call-info .usr-info figure {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 2px white;
  overflow: hidden;
}
.call-info .usr-info figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.call-info .usr-info figure.speaking {
  border: solid 2px var(--primary-color);
}
.call-info .usr-info .userDtls {
  display: flex;
  flex-direction: column;
}
.call-info .usr-info .userDtls .name {
  font-weight: bold;
}
.call-info .usr-info .userDtls .desig {
  font-weight: 500;
  font-size: 0.8rem;
}
.call-info .options {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.call-info .options .small-options {
  display: flex;
  gap: 20px;
  width: fit-content;
  z-index: 2;
}
.call-info .options .small-options .muted {
  position: relative;
}
.call-info .options .small-options .muted:before,
.muted:after {
  position: absolute;
  content: "";
  background: #dd3f3f;
  display: block;
  width: 2%;
  transform: rotate(45deg);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.call-info .options .small-options span {
  border-radius: 50%;
  padding: 5px;
  user-select: none;
  cursor: pointer;
  display: flex;
}
.call-info .options .small-options span .cam {
  width: 25px;
}
.call-info .options .small-options .end-call {
  background: #dd3f3f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.call-info .options .small-options .end-call img {
  width: 90%;
  transform: rotate(-45deg);
}
.call-info .options .btc {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}

.notification section .icons {
  cursor: pointer;
  user-select: none;
  height: 20px;
}
</style>